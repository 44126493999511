import React from 'react';

import formTemplate from './data-form-template';
import DatesHelper from '../../../../../tools/DatesHelper';
import {
  SEXES, BOOLEANS, COUNTRIES, DNA_TYPES,
  EID_TYPES, REGISTERS, HORN_STATUSES
} from '../../../../../tools/translation-constants';

class AnimalDataForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = this._initializeState(props.currentAnimal);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.refreshBreedComposition = this.refreshBreedComposition.bind(this);
  }

  _initializeState(currentAnimal) {
    return {
      editing: false,
      changes: { animalId: currentAnimal.animalId },
      animalData: {
        lID: currentAnimal.lID || '',
        vID: currentAnimal.vID || '',
        electronicId: currentAnimal.electronicId || '',
        name: currentAnimal.name || '',
        passportNumber: currentAnimal.passportNumber || '',
        dateOfBirth: currentAnimal.dateOfBirth ? DatesHelper.parseDate(currentAnimal.dateOfBirth).toDate() : null,
        sex: SEXES.find(s => s.id === currentAnimal.sex),
        aClass: currentAnimal.aClass,
        breed: { breedsId: currentAnimal.breed.breedsId, breedName: currentAnimal.breed.breedName, breedShort: currentAnimal.breed.breedShort },
        formattedBreedComposition: currentAnimal.formattedBreedComposition,
        color: { colorID: currentAnimal.color.colorID, colorName: currentAnimal.color.colorName },
        sireID: currentAnimal.sire ? { animalId: currentAnimal.sire.animalId, lID: currentAnimal.sire.lID } : {},
        damID: currentAnimal.dam ? { animalId: currentAnimal.dam.animalId, lID: currentAnimal.dam.lID } : {},
        dNAnumber: currentAnimal.dNAnumber || '',
        registrationDate: currentAnimal.registrationDate ? DatesHelper.parseDate(currentAnimal.registrationDate).toDate() : null,
        eIDType: EID_TYPES.find(eIdType => eIdType.id === currentAnimal.eIDType),
        hornStatusID: HORN_STATUSES.find(h => h.id === currentAnimal.hornStatusID),
        countryFrom: COUNTRIES.find(c => c.id === currentAnimal.countryFrom),
        register: REGISTERS.find(register => register.id === currentAnimal.register),
        herdBookOut: currentAnimal.herdBookOut || '',
        underSelection: BOOLEANS.find(b => b.id === currentAnimal.underSelection),
        holdTo: currentAnimal.holdTo ? DatesHelper.parseDate(currentAnimal.holdTo).toDate() : null,
        dNAType: DNA_TYPES.find(dnaType => dnaType.id === currentAnimal.dNAType),
        fromEmbryoTransfer: BOOLEANS.find(b => b.id === currentAnimal.fromEmbryoTransfer),
        comment: currentAnimal.comment || '',
        breedComposition: currentAnimal.breedComposition,
        animalId: currentAnimal.animalId
      }
    };
  }

  toggleEditing() {
    const isEditing = !this.state.editing;
    this.setState({ editing: isEditing });
    if (!isEditing) {
      this.setState(this._initializeState(this.props.currentAnimal));
    }
  }

  refreshBreedComposition(data) {
    let objCopy = {...this.state.animalData};
    objCopy.formattedBreedComposition = data;

    this.setState({
      animalData: objCopy
    });
  }

  _resetFormAfterUpdates() {
    this.setState({ editing: false });
  }

  handleChange(event) {
    const targetName = event.target.name;
    const targetVal = event.target.value;

    this.setState(prevState => {
      const updatedChanges = this._registerChanges(prevState.changes, targetName, targetVal);
      return {
        animalData: {
          ...prevState.animalData,
          [targetName]: targetVal
        },
        changes: updatedChanges
      };
    });
  }

  handleSubmit(event, updateAnimal) {
    event.preventDefault();
    if (Object.keys(this.state.changes).length > 1) {
      updateAnimal({
        variables:
          { animalUpdates: this.state.changes }
      });
    }
  }

  _registerChanges(allChanges, prop, val) {
    const customObjects = ['sex', 'eIDType', 'hornStatusID', 'register', 'dNAType', 'countryFrom', 'underSelection', 'fromEmbryoTransfer'];
    if (prop === 'breed') {
      allChanges['breedsID'] = val.breedsId
    } else if (prop === 'color') {
      allChanges['colorID'] = val.colorID
    } else if (customObjects.indexOf(prop) >= 0) {
      allChanges[prop] = val.id
    } else if (prop === 'damID' || prop === 'sireID') {
      // REVIEW Do we accept null value for sire or dam?
      if (val === null || val.animalId === null) {
        return allChanges;
      }
      allChanges[prop] = val.animalId
    } else if (prop === 'aClass') {
      allChanges['classID'] = val.classID
    } else {
      allChanges[prop] = val;
    }
    return allChanges;
  }

  render = formTemplate;
}

export default AnimalDataForm;