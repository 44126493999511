import React, { Component } from "react";
import Header from "./header";
import Footer from "./footer";
import SideMenu from "./sidemenu";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from 'react-localize-redux';
import './layout.css';


class Index extends Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                { name: 'Bulgarian', code: 'bg' },
                { name: 'English', code: 'en' }
            ],
            options: { renderToStaticMarkup }
          });
        this.state = {
            isMenuOpen: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
    }

    render = () => {
        return (
            <div id="wrapper" style={{ margin: 0 }}>
                <SideMenu isOpen={this.state.isMenuOpen} auth={this.props.auth}/>
                <section className={'right-side ' + (this.state.isMenuOpen ? '' : 'full-width')}>
                    <Header onMenuToggle={this.toggleMenu} />
                    {this.props.children}
                    <Footer />
                </section>
            </div>
        )
    }
}

export default withLocalize(Index);