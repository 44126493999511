import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import { UPDATE_ANIMAL_VACCINE, GET_ANIMAL_VACCINE } from '../../../../../../data/animal/animal-query';
import FarmClientDropdown from '../farm-client-dropdown';


class EditVaccineModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            vaccineObj: props.vaccineObj,
            changes: {
                animalID: props.vaccineObj.animalID,
                animalVaccineID: props.vaccineObj.animalVaccineID
            },
            initialState: props.vaccineObj,
            submitDisabled: true,
            tableType:'vaccine'
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });

        if (!this.state.visible) {
            this.resetChanges();
        }
    }


    handleFormChange(e) {
        let objCopy = { ...this.state.vaccineObj };
        let changes = { ...this.state.changes };
        if ('farmClientID' === e.target.name) {
            objCopy[e.target.name] = e.target.value.farmClientID;
            changes[e.target.name] = e.target.value.farmClientID;
        } else {
            objCopy[e.target.name] = e.target.value;
            changes[e.target.name] = e.target.value;
        }
        const isFormSubmitable = Object.keys(changes).length > 1 ? false : true;
        this.setState({
            vaccineObj: objCopy,
            changes: changes,
            submitDisabled: isFormSubmitable
        });
    }

    handleSubmit(e, updateAnimalVaccineTrigger) {
        e.preventDefault();

        updateAnimalVaccineTrigger({
            variables:
            {
                animalVaccineUpdates: this.state.changes
            }
        });
        this.setState({
            initialState:this.state.vaccineObj
        });
        this.resetChanges();
    }

    resetChanges() {
        this.setState({
            changes: {
                animalID: this.state.vaccineObj.animalID,
                animalVaccineID: this.state.vaccineObj.animalVaccineID
            },
            submitDisabled:true,
            vaccineObj: this.state.initialState
        });
    }

    render() {
        return (
            <Mutation mutation={UPDATE_ANIMAL_VACCINE} awaitRefetchQueries={true}
                refetchQueries={[{ query: GET_ANIMAL_VACCINE, variables: { id: this.state.vaccineObj.animalID } }]}
                onCompleted={() => this.toggleDialog()}
            >
                {(updateAnimalVaccineTrigger) => (
                    <div className="d-inline-block">
                        <button className="button-icon" onClick={this.toggleDialog}><FontAwesomeIcon icon={'pen'} /></button>
                        {this.state.visible && <Dialog className='health-modal' title={"Редактирай събитие"} onClose={this.toggleDialog} minWidth='500px' height="80%">
                            <form className="health-form" onSubmit={e => this.handleSubmit(e, updateAnimalVaccineTrigger)}>
                                <Row>
                                    <Col lg={{ size: 8, offset: 2 }}>

                                        <div className="form-group">
                                            <label>Тип</label>
                                            <Input type="text" name="animalVaccineDrug" value={this.state.vaccineObj.animalVaccineDrug ?this.state.vaccineObj.animalVaccineDrug  : ""} onChange={this.handleFormChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Ветеринарен лекар</label>
                                            <FarmClientDropdown tableType={this.state.tableType} farmClientID={this.state.vaccineObj.farmClientID} handleFormChange={this.handleFormChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Дата</label>
                                            <DatePicker format="dd.MM.yyyy" value={new Date(this.state.vaccineObj.animalVaccineDate)} onChange={this.handleFormChange} name="animalVaccineDate" />
                                        </div>

                                        <div>
                                            Информация
                                           <hr />
                                            <div className="form-group">
                                                <label>Доза</label>
                                                <Input type="text" name="animalVaccineDose" value={this.state.vaccineObj.animalVaccineDose ?this.state.vaccineObj.animalVaccineDose :""} onChange={this.handleFormChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Месеци до следваща ваксина</label>
                                                <Input type="number" name="animalVaccineEndMonths" value={this.state.vaccineObj.animalVaccineEndMonths} onChange={this.handleFormChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Цена</label>
                                                <Input type="number" name="animalVaccinePrice" value={this.state.vaccineObj.animalVaccinePrice} onChange={this.handleFormChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Карентен срок</label>
                                                <Input type="number" name="animalVaccineKarentDays" value={this.state.vaccineObj.animalVaccineKarentDays} onChange={this.handleFormChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Бележка</label>
                                                <textarea className="k-textarea" name="animalVaccineComment" value={this.state.vaccineObj.animalVaccineComment ?this.state.vaccineObj.animalVaccineComment : ""} onChange={this.handleFormChange}  ></textarea>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button type="submit" disabled={this.state.submitDisabled} className="btn btn-success mr-3">Добави</button>
                                            <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Dialog>}
                    </div>
                )}
            </Mutation>
        );
    }
}

export default EditVaccineModal;