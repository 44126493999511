import React from "react";
import { useQuery } from '@apollo/react-hooks';


export default ({ children, ...props }) => {
  const { loading, error, data } = useQuery(props.query, { ...props });
  if (loading) return <p>Loading...</p>;

  if (error) {
    if (error.networkError.statusCode === 401) {
      props.auth.login();
      return <div>Redirecting...</div>
    }

    return <div>Error :(</div>
  }

  return children(data);
}
