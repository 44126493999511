import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class DeleteBcsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            bCSObj: props.bCSObj
        };
        this.toggleDialog = this.toggleDialog.bind(this);
  
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });
       
    }


    handleSubmit(e) {
        e.preventDefault();
        this.toggleDialog();
    }

    render() {
        return (
            <div className="d-inline-block">
                <button className="button-icon mr-4" onClick={this.toggleDialog}><FontAwesomeIcon icon={'trash'} /></button>
                {this.state.visible && <Dialog className='health-modal' title={"Изтрий събитие"} onClose={this.toggleDialog} minWidth='500px'>
                    <form className="health-form" onSubmit={e => this.handleSubmit(e)}>
                        <Row>
                            <Col lg={{ size: 12 }}>
                                <div className='mb-4 text-center'>
                                    Сигурни ли сте,че искате да изтриете събитието?
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="btn btn-danger mr-3">Изтрий</button>
                                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Dialog>}
            </div>
        );
    }
}

export default DeleteBcsModal;