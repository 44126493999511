import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import Query from "../../../../../data/query";
import { GET_ANIMAL_EVENTS } from "../../../../../data/animal/animal-query";

class ReproductionTab extends React.Component {
  render = _ => {
    return <Query query={ GET_ANIMAL_EVENTS } variables={{ id: this.props.animal.animalId }}>
          {({ animal }) => {
            
            return <div className="tab">
                    <div className="tab-head">
                      <div className="d-flex align-items-center mb-4">
                        <h3 className="mb-0">| РЕПРОДУКЦИЯ |</h3>
                        <div className="d-flex ml-3">
                          <button className="button-icon icon-color-success">
                            <FontAwesomeIcon icon={'question-circle'} />
                          </button>
                          <strong className="ml-3">| БРЕМЕННОСТИ |<span className="text-success"> 3 </span>| </strong>
                          <strong className="ml-2">| СЪРВИСИ |<span className="text-success"> 3 </span>| </strong>
                          <strong className="ml-2">| АБОРТИ |<span className="text-accent"> 1 </span>| </strong>
                        </div>
                      </div>
                    </div>
                    <div className="table-inner mb-5">
                      <div className="d-flex align-items-center mb-1">
                        <p className="text-muted mb-0 mr-3">| СЪРВИСИ | ИЗКУСТВЕНО ОСЕМЕНЯВАНЕ |</p>
                        <button className="button-icon icon-color-success">
                          <FontAwesomeIcon icon={'question-circle'} />
                        </button>
                      </div>
                      <button className="btn btn-danger">ДОБАВИ СЪБИТИЕ</button>
                      <div className="d-flex justify-content-between align-items-baseline">
                        <small className="text-muted mb-1">Тук добавяте изкуствено осеменяване и индивидуални заплождания с бик - разплодник! Сървиси от сървис групи се записват автоматично!</small>
                        <div className="tab-head-actions">
                          <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                        </div>
                      </div>
                      <table className="table table-custom table-dark">
                        <thead>
                          <tr>
                            <th>Дата</th>
                            <th>Вид сървис</th>
                            <th>Бик</th>
                            <th>Осеменител / Отговорник</th>
                            <th>Коментар</th>
                            <th>Инструменти</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>07/09/17</td>
                            <td>Eстествено покриване</td>
                            <td>FR6414616504 (BAQ)</td>
                            <td>Георги Великов</td>
                            <td>Ректална диагностика</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>15/04/18</td>
                            <td>Сървис група</td>
                            <td>FR6414616504 (BAQ)</td>
                            <td>Кирил Богданов</td>
                            <td>В сървис от 15/04/18 до 15/07/18</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>01/02/19</td>
                            <td>Изкуствено осеменяване</td>
                            <td>(BAQ) BUNLAHY JOHN G.</td>
                            <td>Георги Великов</td>
                            <td>Ехограф</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="table-inner mb-5">
                      <div className="d-flex align-items-center mb-1">
                        <p className="text-muted mb-0 mr-3">| БРЕМЕННОСТ АБОРТИ |</p>
                        <button className="button-icon icon-color-success">
                          <FontAwesomeIcon icon={'question-circle'} />
                        </button>
                      </div>
                      <button className="btn btn-danger">ДОБАВИ СЪБИТИЕ</button>
                      <div className="d-flex justify-content-between align-items-center">
                        <small className="text-muted mb-1">Тук добавяте установени бременности!</small>
                        <div className="tab-head-actions">
                          <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                        </div>
                      </div>
                      <table className="table table-custom table-dark">
                        <thead>
                          <tr>
                            <th>Дата</th>
                            <th>Събитие</th>
                            <th>Стадиий в дни</th>
                            <th>Ветеринарен лекар</th>
                            <th>Коментар</th>
                            <th>Инструменти</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>07/09/17</td>
                            <td>Бременност</td>
                            <td>133</td>
                            <td>Георги Великов</td>
                            <td></td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>09/10/18</td>
                            <td>Бременност</td>
                            <td>200</td>
                            <td>Георги Великов</td>
                            <td></td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>04/06/19</td>
                            <td>Бременност</td>
                            <td>133</td>
                            <td>Георги Великов</td>
                            <td></td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>15/06/19</td>
                            <td>Аборт</td>
                            <td>144</td>
                            <td>Кирил Богданов</td>
                            <td>Причина неустановена, Аборт в периода 3-6 месеца</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="table-inner mb-5">
                      <div className="d-flex align-items-center mb-1">
                        <p className="text-muted mb-0 mr-3">| ОТЕЛВАНЕ |</p>
                        <button className="button-icon icon-color-success">
                          <FontAwesomeIcon icon={'question-circle'} />
                        </button>
                      </div>
                      <button className="btn btn-danger">ДОБАВИ СЪБИТИЕ</button>
                      <div className="d-flex justify-content-between align-items-center">
                        <small className="text-muted mb-1">Тук добавяте новите телета!</small>
                        <div className="tab-head-actions">
                          <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                        </div>
                      </div>
                      <table className="table table-custom table-dark">
                        <thead>
                          <tr>
                            <th>Отелване дата</th>
                            <th>ИНАО</th>
                            <th>Теле УМ</th>
                            <th>ПОЛ</th>
                            <th>СТО</th>
                            <th>Прод. брем.</th>
                            <th>Баща</th>
                            <th>КГ 0 (д)</th>
                            <th>КГ 205 (д).</th>
                            <th>КГ 365 (д).</th>
                            <th>СДП кг/д 0 -205 (д).</th>
                            <th>СДП кг/д 205 -365 (д).</th>
                            <th>Бележка</th>
                            <th>Инструменти</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>18/06/2019</td>
                            <td>0</td>
                            <td>BG32219314</td>
                            <td>М</td>
                            <td>1</td>
                            <td>282</td>
                            <td>FR1217150159 (BAQ)</td>
                            <td>34</td>
                            <td>325</td>
                            <td>550</td>
                            <td>1,50</td>
                            <td>2,05</td>
                            <td></td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="table-inner mb-5">
                      <div className="d-flex align-items-center mb-1">
                        <p className="text-muted mb-0 mr-3">| ЕСТРУС |</p>
                        <button className="button-icon icon-color-success">
                          <FontAwesomeIcon icon={'question-circle'} />
                        </button>
                      </div>
                      <button className="btn btn-danger">ДОБАВИ СЪБИТИЕ</button>
                      <div className="d-flex justify-content-between align-items-center">
                        <small className="text-muted mb-1">Тук добавяте еструс и синхронизация!</small>
                        <div className="tab-head-actions">
                          <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                          <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                        </div>
                      </div>
                      <table className="table table-custom table-dark">
                        <thead>
                          <tr>
                            <th>Дата</th>
                            <th>Събитие</th>
                            <th>Отговорник</th>
                            <th>Коментар</th>
                            <th>Инструменти</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>07/09/17</td>
                            <td>Отчетен еструс</td>
                            <td>Кирил Богданов</td>
                            <td>08:00 Пасище база, B</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>09/10/18</td>
                            <td>Поставен Prid Delta</td>
                            <td>Георги Великов</td>
                            <td>08:00, Пасище база, B, Втори хормон на 13/10</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                          <tr>
                            <td>04/06/19</td>
                            <td>Ехограф</td>
                            <td>Георги Великов</td>
                            <td>08:00, Пасище база, B, Втори хормон на 13/10</td>
                            <td className="text-center">
                              <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                              <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
          }}
        </Query>
  }
}

const mapStateToProps = ({ animal }) => ({ animal });

export default connect(mapStateToProps)(ReproductionTab);