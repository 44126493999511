import React from 'react';
import { Row, Col } from 'reactstrap';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Mutation from '../../../../../data/mutation';
import { ADD_NOTE, GET_ANIMAL_NOTES } from '../../../../../data/animal/animal-query';

class AddNoteModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      animalID:props.animalId,
      noteToAdd: {
        animalID: props.animalId,
        dateNote: new Date(),
        noteTopic: '',
        note: ''
      }
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal() {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });

    this.resetState();
  }

  handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    const currentNoteToAdd = { ...this.state.noteToAdd };
    currentNoteToAdd[name] = value;
    this.setState({ noteToAdd: currentNoteToAdd });
  }

  handleSubmit(e, addNote) {
    e.preventDefault();
    addNote({
      variables:
        { noteToAdd: this.state.noteToAdd }
    });
  }

  submitDisabled() {
    return this.state.noteToAdd.dateNote === null || this.state.noteToAdd.noteTopic.length === 0
      || this.state.noteToAdd.note.length === 0;
  }

  resetState() {
    const animalID = this.state.animalID;
    this.setState({
      animalID:animalID,
      noteToAdd: {
        animalID: animalID,
        dateNote: new Date(),
        noteTopic: '',
        note: ''
      }
    })
}

  render() {
    return (
      <Mutation mutation={ADD_NOTE} awaitRefetchQueries={true}
        refetchQueries={[{ query: GET_ANIMAL_NOTES, variables: { id: this.state.animalID} }]}
        onCompleted={() => this.closeModal()}
      >
        {(addNoteTrigger) => (
          <div>
            <button className="btn btn-danger" onClick={this.openModal}>ДОБАВИ БЕЛЕЖКА</button>
            {this.state.visible && <Dialog className="health-modal" title={"Добави събитие"} onClose={this.toggleDialog} minWidth='500px' height="70%">
              <form className="health-form" onSubmit={e => this.handleSubmit(e, addNoteTrigger)}>
                <Row>
                  <Col lg={{ size: 8, offset: 2 }}>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker format="dd.MM.yyyy" value={this.state.noteToAdd.dateNote} name="dateNote" onChange={e => this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                      <label>Тема</label>
                      <Input type="text" name="noteTopic" onChange={e => this.handleChange(e)} value={this.state.noteToAdd.noteTopic || ''} />
                    </div>
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" name="note" onChange={e => this.handleChange(e)} value={this.state.noteToAdd.note} ></textarea>
                    </div>
                    <div className="d-flex">
                      <button type="submit" disabled={this.submitDisabled()} className="btn btn-success mr-3">Добави</button>
                      <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Отказ</button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Dialog>}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddNoteModal;