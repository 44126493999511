import React from 'react';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from '../../layout/breadcrumbs';
import feeding from '../../../assets/img/icons/ICON6.png';
import calves from '../../../assets/img/icons/ICON13.png';

import './events.css';



const EventsFeeding = props => {
  const breadcrumbs = [{ title: 'Събития', href: '/events' }, { title: 'Хранене и Фуражи' }];
  return (
    <div className="container-fluid">
      <Breadcrumbs pathLinks={breadcrumbs} />
      <Row>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={calves} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ХРАНЕНЕ <br /> ГРУПИ И ДАЖБИ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={feeding} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ЗАПРИХОЖДАВАНЕ ФУРАЖНА НАЛИЧНОСТ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={feeding} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ОТПИСВАНЕ ФУРАЖНА НАЛИЧНОСТ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={feeding} alt="link-icon" /></figure>
            <p className="nav-card-title">
              СПРАВКА ДВИЖЕНИЯ НА ФУРАЖИ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={feeding} alt="link-icon" /></figure>
            <p className="nav-card-title">
              НАЛИЧНОСТИ НА ФУРАЖИ
            </p>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default EventsFeeding;