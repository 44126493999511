import React from 'react';
import { Row, Col } from 'reactstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import Mutation from '../../../../../data/mutation';
import { UPDATE_ANIMAL_VETIS, ADD_ANIMAL_VETIS } from '../../../../../data/animal/animal-query';
import { BOOLEANS, SEXES } from '../../../../../tools/translation-constants';
import CustomComboBox from './custom-combo-box';

export default function () {
  return (
    <Mutation mutation={ADD_ANIMAL_VETIS}
      update={(cache, { data: { addAnimalVetis } }) => this.vetisUpdateResolver(cache, addAnimalVetis)}>
      {(addVetisTrigger) =>
        <Mutation mutation={UPDATE_ANIMAL_VETIS}
          update={(cache, { data: { updateAnimalVetis } }) => this.vetisUpdateResolver(cache, updateAnimalVetis)}>
          {(updateVetisTrigger) =>
            <form onSubmit={e => this.handleSubmit(e, addVetisTrigger, updateVetisTrigger)}>
              <div className="action-buttons">
                <button type="button" className="btn btn-danger" onClick={this.toggleEditing} >{this.state.editing ? 'ОТКАЖИ' : 'РЕДАКТИРАЙ'}</button>
                {this.state.editing ? <button disabled={Object.keys(this.state.changes).length <= 2} type="submit" className="btn btn-success">ЗАПИШИ</button> : ''}
              </div>
              <fieldset disabled={!this.state.editing}>
                <Row>
                  <Col lg="2">
                    <div className="form-group">
                      <label>ВЕТИС №</label>
                      <Input name="animalVetisLID" value={this.state.animalData.animalVetisLID} onChange={this.handleChange}
                        className={'vetis-' + (this.state.animalData.animalVetisLID === this.state.vetisComparison.lID ? 'match' : 'mismatch')} />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="form-group">
                      <label>EID №</label>
                      <Input name="animalVetisEID" value={this.state.animalData.animalVetisEID} onChange={this.handleChange}
                        className={'vetis-' + (this.state.animalData.animalVetisEID === this.state.vetisComparison.eID ? 'match' : 'mismatch')} />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="form-group">
                      <label>Дата на раждане ВЕТИС</label>
                      <DatePicker name="animalVetisDOB" value={this.state.animalData.animalVetisDOB} format="dd.MM.yyyy"
                        disabled={!this.state.editing} onChange={this.handleChange}
                        className={'vetis-' + (this.state.animalData.animalVetisDOB + '' === this.state.vetisComparison.dateOfBirth + '' ? 'match' : 'mismatch')} />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="form-group">
                      <label>Пол</label>
                      <DropDownList name="animalVetisSex" data={SEXES} textField="text" dataItemKey="id"
                        value={this.state.animalData.animalVetisSex} disabled={!this.state.editing} onChange={this.handleChange}
                        className={'vetis-' + (this.state.animalData.animalVetisSex.id === this.state.vetisComparison.sex ? 'match' : 'mismatch')} />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="form-group">
                      <label>Цвят</label>
                      <DropDownList name="animalVetisColorID" data={this.props.color['items']} textField="colorName" dataItemKey="colorID"
                        value={this.state.animalData.animalVetisColorID} disabled={!this.state.editing} onChange={this.handleChange}
                        className={'vetis-' + (this.state.animalData.animalVetisColorID.colorID === this.state.vetisComparison.colorID ? 'match' : 'mismatch')} />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="form-group">
                      <label>Майка</label>
                      <CustomComboBox name="animalVetisDamLID" onChange={this.handleChange} value={this.state.animalData.animalVetisDamLID}
                        data={this.props.dams.items.map(d => d.lID)} disabled={!this.state.editing}
                        className={'vetis-' + (this.state.animalData.animalVetisDamLID === this.state.vetisComparison.damlID ? 'match' : 'mismatch')}
                      />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="form-group">
                      <label>"Под СК"</label>
                      <DropDownList name="underSelection" data={BOOLEANS} textField="text" dataItemKey="id"
                        value={this.state.animalData.underSelection} disabled={!this.state.editing} onChange={this.handleChange}
                        className={'vetis-' + (this.state.animalData.underSelection.id === this.state.vetisComparison.underSelection.id ? 'match' : 'mismatch')} />
                    </div>
                  </Col>
                </Row>
              </fieldset>
            </form>
          }
        </Mutation>
      }
    </Mutation>
  )
}