import React from 'react';

import EditBcsModal from './edit-bcs-modal';
import DeleteBcsModal from './delete-bcs-modal';
import DatesHelper from '../../../../../../tools/DatesHelper';
import {mapAnimalOTSType} from '../../../animal-mapping-functions';




const BcsTable = props => {
    const generateTableRows = () => {
        return props.bCS.map((bCSObj, i) => {
            return (
                <tr key={i}>
                    <td>{DatesHelper.parseAndFormatDate(bCSObj.bCSDate)}</td>
                    <td>{mapAnimalOTSType(bCSObj.animalOTS) }</td>
                    <td>{bCSObj.comment ? bCSObj.comment : '' }</td>
                    <td>
                        {bCSObj.personInCharge ? bCSObj.personInCharge.farmClientName: ''}    
                    </td>
                    <td className="text-center" >
                     <DeleteBcsModal bCSObj={bCSObj}/>
                     <EditBcsModal bCSObj={bCSObj} />
                    </td>
                </tr>
            );
        });
    }
  
    return (

        <table className="table table-custom table-dark">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>ОТС</th>
                    <th>Бележка</th>
                    <th>Отговорник</th>
                    <th>Инструменти</th>
                </tr>
            </thead>
            <tbody>
                {generateTableRows()}
            </tbody>
        </table>
    );
}

export default BcsTable;