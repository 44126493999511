import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Breadcrumbs from '../../../layout/breadcrumbs';

import entries from '../../../../assets/img/icons/ICON20.png';
import dropout from '../../../../assets/img/icons/ICON17.png';
import location from '../../../../assets/img/icons/ICON2.png';


import '../../../views/events/events.css';



const MovementsMain = props => {
    const breadcrumbs = [{ title: 'Животни', href: '/animals' }, { title: 'Движения' }];
    return (
        <div className="container-fluid">
            <Breadcrumbs pathLinks={breadcrumbs} />
            <Row>
                <Col lg="2" className="mb-5">
                    <Link to="/animals/movement/individual-entries" className="nav-card">
                        <figure><img src={entries} alt="link-icon" /></figure>
                        <p className="nav-card-title">ИНДИВИДУАЛНО ВПИСВАНЕ</p>
                    </Link>
                </Col>
                <Col lg="2" className="mb-5">
                    <Link to="/animals/movement/group-entries" className="nav-card">
                        <figure><img src={entries} alt="link-icon" /></figure>
                        <p className="nav-card-title">ГРУПОВО ВПИСВАНЕ</p>
                    </Link>
                </Col>
                <Col lg="2" className="mb-5">
                    <Link to="#" className="nav-card">
                        <figure><img src={location} alt="link-icon" /></figure>
                        <p className="nav-card-title">ПРОМЯНА МЕСТОПОЛОЖЕНИЕ</p>
                    </Link>
                </Col>
                <Col lg="2" className="mb-5">
                    <Link to="#" className="nav-card">
                        <figure><img src={dropout} alt="link-icon" /></figure>
                        <p className="nav-card-title">ОТПАДАНЕ</p>
                    </Link>
                </Col>
            </Row>
        </div>
    );
}

export default MovementsMain;