import React from 'react';

import {
    Chart,
    ChartTitle,
    ChartSeriesItem,
    ChartArea,
    ChartLegend,
    ChartSeries,
    ChartSeriesLabels
  } from '@progress/kendo-react-charts';



const RodeoChartDonut = props => {
    return (
        <Chart donutCenterRender={props.donutCenterRenderer}  >
        <ChartTitle text={props.chartTitle} position="bottom" />
        <ChartArea background="#f1f1f1" height="247px" />
        <ChartSeries  >
          <ChartSeriesItem type="donut" data={props.data} categoryField="kind" field="share" holeSize={80}>
            <ChartSeriesLabels color="#fff" background="none" content={props.labelContent} />
          </ChartSeriesItem>
        </ChartSeries>
        <ChartLegend visible={false} />
      </Chart>
    )
}


export default RodeoChartDonut;