import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Breadcrumbs extends Component {
  render = () => {

    const { pathLinks } = this.props;

    const links = !!pathLinks ? pathLinks.map((l, i) => {
      const title = i === 0 ? l.title : ` / ${l.title}`;
      return !!l.href ? <Link key={i} to={l.href}>{title}</Link> : <span key={i}>{title}</span>
    }) : [];

    return (
      <h1 className="h1-main">
        <Link className="mr-2" to="/"><FontAwesomeIcon icon="home" /></Link>
        {links}
      </h1>
    )
  }
}
