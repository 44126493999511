export const BOOLEANS = [
  { id: true, text: 'Да' },
  { id: false, text: 'Не' }
];

export const SEXES = [
  { id: 'MALE', text: 'Мъжки' },
  { id: 'FEMALE', text: 'Женски' }
];

export const COUNTRIES = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'IRELAND', text: 'Ирландия' },
  { id: 'FRANCE', text: 'Франция' },
  { id: 'GERMANY', text: 'Германия' },
  { id: 'ITALY', text: 'Италия' },
  { id: 'UK', text: 'Великобритания' },
  { id: 'DENMARK', text: 'Дания' },
  { id: 'BULGARIA', text: 'България' }
];

export const DNA_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'SNP', text: 'SNP' },
  { id: 'MICROSATELLITE', text: 'Microsatelite' }
];

export const EID_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'EAR_TAG', text: 'Ушна марка' },
  { id: 'BOLUS', text: 'Болус' }
];

export const REGISTERS = [
  { id: 'NONE', text: 'Няма' },
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'ARMPGB', text: 'АРМПГБ' },
  { id: 'NAMGB', text: 'НАМГБ' }
];

export const HORN_STATUSES = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'HUMMEL', text: 'Безрог' },
  { id: 'HORNED', text: 'Рогат' },
  { id: 'DEHORN', text: 'Обезроговен' }
];

export const BREEDING_STATUSES = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'EMPTY', text: 'Празна' },
  { id: 'PREGNANT', text: 'Бременна' },
  { id: 'ABORTION', text: 'Абортирала' },
  { id: 'IN_SERVICE', text: 'В сървис' }
];

export const DRY_WET_STATUSES = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'WET', text: 'Лактираща' },
  { id: 'DRY', text: 'Сухостойна' }
];

export const WEIGHT_TYPES = [
  { id: 'CONTROL_WEIGHT', text: 'Контролно тегло' },
  { id: 'BIRTH_WEIGHT', text: 'Тегло при раждане' },
  { id: 'WEANING_WEIGHT', text: 'Тегло при отбиване' },
  { id: 'YEARLING_WEIGHT', text: 'Тегло на една година' },
  { id: 'MATURE_WEIGHT', text: 'Тегло на възрастно животно' }
];

export const TYPE_INPUTS = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'PURCHASE', text: 'Покупка' },
  { id: 'TEMPORARY_IN', text: 'Временно в обекта' },
  { id: 'SELF', text: 'Собствено производство' }
];

export const ANIMAL_TUBERCULOSIS_BRUCELLOSIS_TYPES=[
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'POSITIVE', text: 'Позитивен' },
  { id: 'NEGATIVE', text: 'Отрицателен' },
  { id: 'SUSPICIOUS', text: 'Подозрителен' },
]

export const ANIMAL_OTS_TYPES= [
  { id: 'UNKNOWN', text: 'Неопределено' },
  { id: 'ONE', text: '1-Изтощенa' },
  { id: 'TWO', text: '2-Много Слаба' },
  { id: 'THREE', text: '3-Слаба' },
  { id: 'FOUR', text: '4-Умерено Слаба' },
  { id: 'FIVE', text: '5-Оптимално' },
  { id: 'SIX', text: '6-Оптимално замускулено' },
  { id: 'SEVEN', text: '7-Замускулена' },
  { id: 'EIGHT', text: '8-Средно Затлъстели' },
  { id: 'NINE', text: '9-Затлъстели' }
];
export const ONE_TO_NINE = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'ONE', text: '1' },
  { id: 'TWO', text: '2' },
  { id: 'THREE', text: '3' },
  { id: 'FOUR', text: '4' },
  { id: 'FIVE', text: '5' },
  { id: 'SIX', text: '6' },
  { id: 'SEVEN', text: '7' },
  { id: 'EIGHT', text: '8' },
  { id: 'NINE', text: '9' }
];

export const MARBLINGS = [
  { id: 'UNKNOWN', text: 'Неопределен' },
  { id: 'PRIME_1', text: 'Prime 1' },
  { id: 'PRIME_2', text: 'Prime 2' },
  { id: 'CHOICE_1', text: 'Choice 1' },
  { id: 'CHOICE_2', text: 'Choice 2' },
  { id: 'SELECT_1', text: 'Select 1' },
  { id: 'SELECT_2', text: 'Select 2' }
];


export const LOCATION_TYPES = {
    BARN: {
      id: 0,
      text: "barn"
    },
    PASTURE: {
      id: 1,
      text: "pasture"
    },
    SECTOR: {
      id: 2,
      text: "sector"
    },
    BOX: {
      id: 3,
      text: "box"
    },
    PADDOCK: {
      id: 0,
      text: "paddock"
    },
}
