import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from '../../layout/breadcrumbs';
import calf from '../../../assets/img/icons/ICON1.png';
import sharedEvents from '../../../assets/img/icons/ICON3.png';
import pregnancyDiagnosis from '../../../assets/img/icons/ICON4.png';
import calving from '../../../assets/img/icons/ICON13.png';
import feed from '../../../assets/img/icons/ICON6.png';
import vet from '../../../assets/img/icons/ICON7.png';
import services from '../../../assets/img/icons/ICON8.png';
import embrioTransfer from '../../../assets/img/icons/ICON9.png';
import measuring from '../../../assets/img/icons/ICON20.png';

import './events.css';



const EventsMain = props => {
  const breadcrumbs = [{ title: 'Събития'}];

  return (
    <div className="container-fluid">
      <Breadcrumbs pathLinks={breadcrumbs} />
      <Row>
        <Col lg="2" className="mb-5">
          <Link to="events/calfs" className="nav-card">
            <figure><img src={calf} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ТЕЛЕТА СЪБИТИЯ
            </p>
          </Link>
        </Col>
        <Col lg="2" className="mb-5">
          <Link to="events/shared" className="nav-card">
            <figure><img src={sharedEvents} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ОБЩИ СЪБИТИЯ
            </p>
          </Link>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={pregnancyDiagnosis} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ДИАГНОСТИКА <br /> БРЕМЕННОСТ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <Link to="events/calving" className="nav-card">
            <figure><img src={calving} alt="link-icon" /></figure>
            <p className="nav-card-title">
              РАЖДАНИЯ <br /> ОТЕЛВАНИЯ
            </p>
          </Link>
        </Col>
        <Col lg="2" className="mb-5">
          <Link to="events/feeding" className="nav-card">
            <figure><img src={feed} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ФУРАЖИ <br /> ХРАНЕНЕ
            </p>
          </Link>
        </Col>
        <Col lg="2" className="mb-5">
          <Link to="events/veterinary" className="nav-card">
            <figure><img src={vet} alt="link-icon" /></figure>
            <p className="nav-card-title">
              САНИТАРНИ <br /> ВЕТЕРИНАРНИ
            </p>
          </Link>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={services} alt="link-icon" /></figure>
            <p className="nav-card-title">
              СЪРВИСИ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={embrioTransfer} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ЕМБРИОТРАНСФЕР
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={measuring} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ИЗМЕРВАНИЯ
            </p>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default EventsMain;