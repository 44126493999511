import React from 'react';
import { NavLink, Route, Switch } from "react-router-dom";

import DataTab from './data-tab';
import ReproductionTab from './reproduction/reproduction-tab';
import HealthTab from './health-tab';
import BreedingTab from './breeding-tab';
import EventsTab from './events-tab';
import WeightsTab from './weights-tab';

import './animal-card-tab.css';

const AnimalCardTabs = props => {
  const currentAnimal = props.animal;
  return (
    <div>
      <div className="main-nav small-nav container-fluid">
        <ul className="nav nav-pills nav-fill font-weight-bold">
          <li className="nav-item">
            <NavLink exact className="nav-link" to={`/animals/${currentAnimal.animalId}`}>Данни</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/${currentAnimal.animalId}/reproduction`}>Репродукция</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/${currentAnimal.animalId}/health`}>Здраве</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/${currentAnimal.animalId}/breeding`}>Развъдни</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/${currentAnimal.animalId}/events`}>Събития</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/${currentAnimal.animalId}/weights`}>Тегла</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/demo">Табло крава</NavLink>
          </li>
        </ul>
      </div>
      <div className="container-fluid container-s">
        <Switch>
          <Route path="/animals/:id/reproduction" component={ReproductionTab} />
          <Route path="/animals/:id/health"  render={(props) => <HealthTab {...props} currentAnimalId={currentAnimal.animalId} />} />
          <Route path="/animals/:id/breeding" render={(props) => <BreedingTab {...props} currentAnimal={currentAnimal} />} />
          <Route path="/animals/:id/events" component={(props) => <EventsTab {...props} currentAnimalId={currentAnimal.animalId}/>} />
          <Route path="/animals/:id/weights" render={(props) => <WeightsTab {...props} currentAnimal={currentAnimal} />} />
          <Route path="/animals/:id/" render={(props) => <DataTab {...props} currentAnimal={currentAnimal} />} />
        </Switch>
      </div>
    </div>
  )
};

export default AnimalCardTabs;