import React, { Component } from "react";
// import RodeoChartPie from "./rodeo-chart-pie";
// import VersionLogs from "./version-logs";
// import FarmEventTypes from "./farm-event-types";
import DashboardCard from "../../lib/dashboard-card/dashboard-card"
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import RodeoTableLogs from './tables/rodeo-table-logs';
import RodeoTableReminders from './tables/rodeo-table-reminders';
import RodeoChartCalvesProduction from './charts/rodeo-chart-calves-production';
import RodeoChartDonut from './charts/rodeo-chart-donut';
import './home.css';
import { Button } from "@progress/kendo-react-buttons";
import Breadcrumbs from "../../layout/breadcrumbs";

export default class Home extends Component {

  render = () => {
    // data for demo
    const data = [
      {
        data: [{
          "kind": "", "share": 0.175, "color": '#A6CEE3'
        }, {
          "kind": "", "share": 0.15, "color": '#B2DF8A'
        }, {
          "kind": "", "share": 0.6, "color": '#33A02C'
        }, {
          "kind": "", "share": 0.01, "color": '#1F78B4'
        }],
        donutCenterRenderer: 'Животни',
        chartTitle: 'Животни във фермата',
        number: 543
      },
      {
        data: [{
          "kind": "", "share": 0.6, "color": '#EE624B'
        }, {
          "kind": "", "share": 0.05, "color": '#1F78B4'
        }, {
          "kind": "", "share": 0.25, "color": '#7CCE56'
        }, {
          "kind": "", "share": 0.1, "color": '#1F78B4'
        }],
        donutCenterRenderer: 'Крави',
        chartTitle: 'По репродуктивен статус',
        number: 88
      },
      {
        data: [{
          "kind": "", "share": 0.05, "color": '#1F78B4'
        }, {
          "kind": "", "share": 0.2, "color": '#A6CEE3'
        }, {
          "kind": "", "share": 0.7, "color": '#B2DF8A'
        }, {
          "kind": "", "share": 0.05, "color": '#1F78B4'
        }],
        donutCenterRenderer: 'Крави',
        chartTitle: 'По брой отелвания',
        number: 148
      },
      {
        data: [{
          "kind": "", "share": 0.05, "color": '#FFFFF'
        }, {
          "kind": "", "share": 0.2, "color": '#7CCE56'
        }, {
          "kind": "", "share": 0.7, "color": '#EE624B'
        }, {
          "kind": "", "share": 0.05, "color": '#1F78B4'
        }],
        donutCenterRenderer: 'Животни',
        chartTitle: 'Грешки ВЕТИС',
        number: 148
      }
    ]

    const homeDonuts = (
      data.map((el, index) => {
        const donutCenterRenderer = () => (<div className='chart-circle-text mt-1'><div className='d-block mb-1'>{el.number}</div> {el.donutCenterRenderer}</div>);
        const labelContent = (e) => (e.category);
        return <Col className="mb-3" lg="3" key={index}>
          <RodeoChartDonut data={el.data} chartTitle={el.chartTitle} donutCenterRenderer={donutCenterRenderer} labelContent={labelContent} />
        </Col>
      })
    )

    const user = JSON.parse(localStorage.getItem("user")) || { };

    return <div id="home" className="container-fluid">
      {/* <DashboardAnimals {...this.props}/>, 
            <hr />, */}
      {/* <div className="row m-0">
        <div className="col-sm-12">
          <RodeoChartPie {...this.props} />
        </div>


      </div>
      <div className="row mx-0 my-2">
        <div className="col-sm-6">
          <VersionLogs {...this.props} />
        </div>
        <div className="col-sm-6">
          <FarmEventTypes {...this.props} />
        </div>

      </div> */}
      <h2 style={{paddingLeft: 20}}>Hello {user.nickname} <Button onClick={this.props.auth.logout}>Logout</Button></h2>
      <Breadcrumbs/>
      <Row>
        <Col lg='12'>
          <DashboardCard className="dashboard-card-col mb-3">
            <Row>
              <Col lg="4">
                <div className="card-heading">
                  <div className="d-flex mb-2 justify-content-start">
                    <h5 className="text-left">Животни</h5>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="m-0 text-left small">Общо 543 животни в 5 локации</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Бикове разплод</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="card-col-content">4</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Крави</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="card-col-content">150</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Юници</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="card-col-content">89</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Бикове угояване</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="card-col-content">300</p>
                </div>
              </Col>
            </Row>
          </DashboardCard>
        </Col>
        <Col lg='12'>
          <DashboardCard className="dashboard-card-col mb-3">
            <Row>
              <Col lg="4">
                <div className="card-heading">
                  <div className="d-flex mb-2 justify-content-start">
                    <h5 className="text-left">Репродукция</h5>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="m-0 text-left small">Общо 54 животни в 5 локации</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col mb-2">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Отелени</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                    <span className="small ml-3 info-text-red">0%</span>
                  </div>
                  <p className="card-col-content">50</p>
                </div>
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Без статус</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                    <span className="small ml-3 info-text-red">30%</span>
                  </div>
                  <p className="card-col-content">30</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Бременни</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                    <span className="small ml-3 info-text-red">40%</span>
                  </div>
                  <p className="card-col-content">50</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">В сървис</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                    <span className="small ml-3 info-text-red">2%</span>
                  </div>
                  <p className="card-col-content">0</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Осеменени</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                    <span className="small ml-3 info-text-red">30%</span>
                  </div>
                  <p className="card-col-content">4</p>
                </div>
              </Col>
            </Row>
          </DashboardCard>
        </Col>
      </Row>
      <Row className='home-donut-charts'>
        {homeDonuts}
      </Row>
      <Row>
        <Col lg='12'>
          <DashboardCard className="dashboard-card-col mb-3">
            <Row>
              <Col lg="4">
                <div className="card-heading">
                  <div className="d-flex mb-2 justify-content-start">
                    <h5 className="text-left">Крави по брой отелвания</h5>
                  </div>
                  <p className="m-0 text-left small">Общо 54 животни в 1 локации</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">0 отелвания</p>
                  </div>
                  <p className="card-col-content">4</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">1 отелване</p>
                  </div>
                  <p className="card-col-content">4</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">2 отелвания</p>
                  </div>
                  <p className="card-col-content">50</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">+ 3 отелвания</p>
                  </div>
                  <p className="card-col-content">90</p>
                </div>
              </Col>
            </Row>
          </DashboardCard>
        </Col>
        <Col lg='12'>
          <DashboardCard className="dashboard-card-col mb-3">
            <Row>
              <Col lg="4">
                <div className="card-heading">
                  <div className="d-flex mb-2 justify-content-start">
                    <h5 className="text-left">Телета 2019</h5>
                  </div>
                  <p className="m-0 text-left small">Общо 54 животни в 3 локации</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Мъжки</p>
                  </div>
                  <p className="card-col-content">15</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Женски</p>
                  </div>
                  <p className="card-col-content">50</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Отбити мъжки</p>
                  </div>
                  <p className="card-col-content">0</p>
                </div>
              </Col>
              <Col lg="2">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Отбити женски</p>
                  </div>
                  <p className="card-col-content">0</p>
                </div>
              </Col>
            </Row>
          </DashboardCard>
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="mb-4">
          <DashboardCard className="pl-0 pr-0 table-container">
            <RodeoTableReminders {...this.props} />
          </DashboardCard>
        </Col>
        <Col lg="6" className="mb-4">
          <DashboardCard className="dashboard-card-col mb-3">
            <Row>
              <Col lg="6">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Брой телета на крава</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="card-col-content custom-font-size">0,80 телета/крава</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="card-col">
                  <div className="d-flex align-items-baseline">
                    <p className="text-left">Интервал на отелване</p>
                    <button className="button-icon icon-color-success">
                      <FontAwesomeIcon icon={'question-circle'} />
                    </button>
                  </div>
                  <p className="card-col-content custom-font-size">408 дни</p>
                </div>
              </Col>
            </Row>
          </DashboardCard>
        </Col>
        <Col lg="6" className="mb-4">
          <DashboardCard className="pl-0 pr-0 table-container">
            <RodeoTableLogs {...this.props} />
          </DashboardCard>
        </Col>
        <Col lg="6" className="mb-4">
          <DashboardCard className="pl-0 pr-0 table-container">
            <RodeoChartCalvesProduction {...this.props} />
          </DashboardCard>
        </Col>
      </Row>
    </div>
  }
}