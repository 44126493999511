
import React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Query from "../../../../data/query";
import GET_FARM_EVENTS from "../../../../data/farm-events/farm-events-query";


const RodeoTableReminders = props => {

    return (
        <div>
            <h5 className="text-center mb-4" >Напомняния</h5>
            <Query {...props} query={GET_FARM_EVENTS}>
                {({ farmEvents }) => (
                    <Grid
                        id="farm-event-type-grid"
                        data={farmEvents.items}
                        total={farmEvents.totalCount}
                        className='table table-custom table-light'
                        style={{ height: '300px' }}
                    >
                        <Column field="farmEventID" title="Събитие" cell={props => <td>{props.dataItem.farmEventType.farmEventTypeName}</td>} />
                        <Column field="userID" title="Потребител" width="150px" />
                        <Column field="dateCreate" title="Дата на събитието" width="200px" />
                    </Grid>
                )}
            </Query>
        </div>

    )
}

export default RodeoTableReminders;
