import React, { Component } from "react";

import Query from "../../../data/query";
import { ANIMALS_GRID } from "../../../data/animal/animal-query";
import AnimalsList from "./animals-list";
import Breadcrumbs from "../../layout/breadcrumbs";
import { last, orderBy } from "lodash";
import { getAnimalAgeInMonths, getAnimalLastWeightObj, mapEventCell, generateBreedCompositionString } from "./animal-utilities";

class AnimalsListWrapper extends Component {

  setCustomCells = animals => {
    return animals.map(animal => {
      animal.ageInMonths = getAnimalAgeInMonths(animal.dateOfBirth);
      animal.weight = getAnimalLastWeightObj(animal)['weight'];
      animal.dateWeight = getAnimalLastWeightObj(animal)['dateWeight'];
      animal.event = mapEventCell(animal);
      animal.location = last(animal.animalLocation);
      animal.currentAGD = last(animal.animalADG);
      animal.currentAnimalBCS = last(orderBy(animal.animalbCS, ["bCSDate"], ['asc']));;
      animal.formattedBreedComposition = generateBreedCompositionString(animal);
      return animal;

    });
  }

  render() {
    const breadcrumbs = [{ title: 'Животни', href: '/animals' }, { title: 'Избор на животни' }];
    
    return (
      <div>
        <div className="container-fluid">
          <Breadcrumbs pathLinks={breadcrumbs} />
        </div>
        <Query {...this.props} query={ANIMALS_GRID}>
          {({ animals }) => {
            animals.items = this.setCustomCells(animals.items);
            return <AnimalsList {...this.props} animals={animals}></AnimalsList>
          }}
        </Query>
      </div>
    )
  }
};
export default AnimalsListWrapper;