import React from 'react';
import { NavLink} from "react-router-dom";



const EntriesNavLinks = (props) => {

    const navLinks = props.tabs.links.map((l, index) => {
        return (
            <li className="nav-item" key={index}>
                <NavLink className="nav-link" exact to={l}>{props.tabs.titles[index]}</NavLink>
            </li>
        )
    });

    return (
        <ul className="nav nav-pills nav-fill font-weight-bold">
            {navLinks}
        </ul>
    )
}


export default EntriesNavLinks;