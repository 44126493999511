import React from 'react';
import {
  Chart,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartArea,
  ChartTooltip
} from '@progress/kendo-react-charts';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import DatesHelper from '../../../../../tools/DatesHelper';


const WeightsChart = props => {

  const chartWeights = props.animal.weights.map(w => w.weight);
  const chartDateWeights = props.animal.weights
    .map(w => DatesHelper.parseAndFormatDate(w.dateWeight));

  return (
    <DashboardCard>
      <div className="card-heading">
        <h5>| <span className="text-success">ТЕГЛА</span> | {props.animal.lID} | {props.animal.vID} | {props.animal.breed.breedShort} | {props.animal.ageInMonths} | {props.animal.lastWeight['weight']} |</h5>
        <hr />
      </div>
      <Chart>
        <ChartArea background="#f1f1f1" height="240px" />
        <ChartTooltip />
        <ChartValueAxis>
          <ChartValueAxisItem />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={chartDateWeights} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem data={chartWeights} type="line" color="#EE624B" />
        </ChartSeries>
      </Chart>
    </DashboardCard>
  )
}

export default WeightsChart;