import React from 'react';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import DatesHelper from '../../../../../tools/DatesHelper';
import { getAnimalWeightPerType } from '../../animal-utilities';
import { AnimalWeightEnum } from '../../animal-enums';
import Query from '../../../../../data/query';
import { GET_ADJUSTED_WEIGHTS } from '../../../../../data/animal/animal-query';

const AdjustedWeightsTable = props => {

  const birthWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.birthWeight()) || {};
  const weaningWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.weaningWeight()) || {};
  const yearlingWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.yearlingWeight()) || {};
  const matureWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.matureWeight()) || {};
  const techWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.techWeight()) || {};

  return (
    <Query query={GET_ADJUSTED_WEIGHTS} variables={{ id: props.animal.animalId }}>
      {({ animal }) => {
        return (
          <DashboardCard isLight={true}>
            <div className="card-heading">
              <h5>| <span className="text-success">РАЗВЪДНИ ТЕГЛА</span> | {props.animal.lID} | {props.animal.vID} | {props.animal.breed.breedShort} | {props.animal.ageInMonths} | {props.animal.lastWeight['weight']} |</h5>
              <hr />
            </div>
            <table className="table table-custom table-light">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Тегло</th>
                  <th>Приравнено</th>
                  <th>Вид</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{birthWeight.dateWeight ? DatesHelper.parseAndFormatDate(birthWeight.dateWeight) : '-'}</td>
                  <td>{birthWeight.weight || '-'}</td>
                  <td>{animal.adjustedBirthWeight || '-'}</td>
                  <td>@0 дни раждане</td>
                </tr>
                <tr>
                  <td>{weaningWeight.dateWeight ? DatesHelper.parseAndFormatDate(weaningWeight.dateWeight) : '-'}</td>
                  <td>{weaningWeight.weight || '-'}</td>
                  <td>{animal.adjustedWeaningWeight || '-'}</td>
                  <td>@200 дни раждане</td>
                </tr>
                <tr>
                  <td>{yearlingWeight.dateWeight ? DatesHelper.parseAndFormatDate(yearlingWeight.dateWeight) : '-'}</td>
                  <td>{yearlingWeight.weight || '-'}</td>
                  <td>{animal.adjustedYearlingWeight || '-'}</td>
                  <td>@365 дни раждане</td>
                </tr>
                <tr>
                  <td>{matureWeight.dateWeight ? DatesHelper.parseAndFormatDate(matureWeight.dateWeight) : '-'}</td>
                  <td>{matureWeight.weight || '-'}</td>
                  <td>{animal.adjustedMatureWeight || '-'}</td>
                  <td>@550 дни раждане</td>
                </tr>
                <tr>
                  <td>{techWeight.dateWeight ? DatesHelper.parseAndFormatDate(techWeight.dateWeight) : '-'}</td>
                  <td>{techWeight.weight || '-'}</td>
                  <td>{animal.adjustedTechWeight || '-'}</td>
                  <td>Клане</td>
                </tr>
              </tbody>
            </table>
          </DashboardCard>
        )
      }}
    </Query>
  );
};

export default AdjustedWeightsTable;