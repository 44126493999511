import React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import Mutation from '../../../../../data/mutation';
import { ONE_TO_NINE, MARBLINGS } from '../../../../../tools/translation-constants';
import { GET_ANIMAL_BREEDING_DATA, UPDATE_ANIMAL_BREEDING_DATA } from '../../../../../data/animal/animal-query';

export default function () {
  return (
    <Mutation mutation={UPDATE_ANIMAL_BREEDING_DATA}
      refetchQueries={[{ query: GET_ANIMAL_BREEDING_DATA, variables: { id: this.props.animalId } }]}
      awaitRefetchQueries={true}
      onCompleted={() => this.toggleEditing()}
    >
      {updateBreedingDataTrigger =>
        <form onSubmit={e => this.handleSubmit(e, updateBreedingDataTrigger)}>
          <Row>
            <Col>
              <div className="action-buttons">
                <button type="button" className="btn btn-danger" onClick={this.toggleEditing}>{this.state.editing ? 'ОТКАЖИ' : 'РЕДАКТИРАЙ'}</button>
                {this.state.editing ? <button disabled={Object.keys(this.state.changes).length <= 1} type="submit" className="btn btn-success">ЗАПИШИ</button> : ''}
              </div>
            </Col>
            <Col xs={8}>
              <fieldset disabled={!this.state.editing}>
                <Row>
                  <Col xs={3}>
                    <div className="form-group">
                      <label>Темперамент</label>
                      <DropDownList name="animalTemperament" disabled={!this.state.editing} data={ONE_TO_NINE}
                        textField="text" dataItemKey="id"
                        onChange={this.handleChange} value={this.state.breedingData.animalTemperament} />
                    </div>
                    <div className="form-group">
                      <label>S.E.U.R.O.P</label>
                      <Input name="sEUROP" value={this.state.breedingData.sEUROP} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Мраморираност</label>
                      <DropDownList name="animalMarbling" disabled={!this.state.editing} data={MARBLINGS}
                        textField="text" dataItemKey="id"
                        onChange={this.handleChange} value={this.state.breedingData.animalMarbling} />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="form-group">
                      <label>Функционалност</label>
                      <DropDownList name="animalFunctionality" disabled={!this.state.editing} data={ONE_TO_NINE}
                        textField="text" dataItemKey="id"
                        onChange={this.handleChange} value={this.state.breedingData.animalFunctionality} />
                    </div>
                    <div className="form-group">
                      <label>Ширина на гърба</label>
                      <Input type="number" name="widthBack" value={this.state.breedingData.widthBack} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Развитие рибица</label>
                      <Input type="number" name="growthRibeye" value={this.state.breedingData.growthRibeye} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Развитие на задната 1/4</label>
                      <Input type="number" name="growthBack" value={this.state.breedingData.growthBack} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Ширина на бедрото</label>
                      <Input type="number" name="widthThigh" value={this.state.breedingData.widthThigh} onChange={this.handleChange} />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="form-group">
                      <label>Мускулатура</label>
                      <DropDownList name="animalMusculature" disabled={!this.state.editing} data={ONE_TO_NINE}
                        textField="text" dataItemKey="id"
                        onChange={this.handleChange} value={this.state.breedingData.animalMusculature} />
                    </div>
                    <div className="form-group">
                      <label>Височина холка | <span className="text-success">см</span> |</label>
                      <Input type="number" name="heightWithers" value={this.state.breedingData.heightWithers} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Дължина гръб | см |</label>
                      <Input type="number" name="lengthBack" value={this.state.breedingData.lengthBack} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Дължина на пелвиса</label>
                      <Input type="number" name="lengthPelvic" value={this.state.breedingData.lengthPelvic} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Ширина хълбоци</label>
                      <Input type="number" name="widthFlank" value={this.state.breedingData.widthFlank} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Ширина на пелвиса</label>
                      <Input type="number" name="widthPelvic" value={this.state.breedingData.widthPelvic} onChange={this.handleChange} />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="form-group">
                      <label>Скелет</label>
                      <DropDownList name="animalSkeleton" disabled={!this.state.editing} data={ONE_TO_NINE}
                        textField="text" dataItemKey="id"
                        onChange={this.handleChange} value={this.state.breedingData.animalSkeleton} />
                    </div>
                    <div className="form-group">
                      <label>Предни крака | <span className="text-success">отпред</span> |</label>
                      <Input type="number" name="frontLegs" value={this.state.breedingData.frontLegs} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Задни крака | <span className="text-success">отзад</span> |</label>
                      <Input type="number" name="backLegs" value={this.state.breedingData.backLegs} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                      <label>Задни крака | <span className="text-success">отстрани</span> |</label>
                      <Input type="number" name="backLegsSide" value={this.state.breedingData.backLegsSide} onChange={this.handleChange} />
                    </div>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
        </form>
      }
    </Mutation>
  );
}