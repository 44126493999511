import React from 'react';
import { mapAnimalTuberBrucelType } from '../../../animal-mapping-functions';
import DatesHelper from '../../../../../../tools/DatesHelper';


const TuberBrucelTable = props => {

    const bruceInfo = (tuberOrBro) => {
        return (
            <div>
                <div> Дата кръвна проба | {tuberOrBro.animalBrucellosisDate ?DatesHelper.parseAndFormatDate(tuberOrBro.animalBrucellosisDate):'-'}</div>
                <div> Взета проба | {tuberOrBro.animalBrucellosisNumber ? tuberOrBro.animalBrucellosisNumber : '-'}</div>
                <div> Резултат | {tuberOrBro.tuberculosisCheckTypeID ?mapAnimalTuberBrucelType(tuberOrBro.tuberculosisCheckTypeID):'-'}</div>
            </div>
        );
    }
    const tuberInfo = (tuberOrBro) => {
        return (
            <div>
                <div> Поставени | {tuberOrBro.tuberculinDose ? `${tuberOrBro.tuberculinDose} мл.` : '-' }  </div>
                <div> Туберкулин | {tuberOrBro.tuberculin ? `${tuberOrBro.tuberculin.tuberculinPackage} мл.` :'-' }  </div>
                <div> Размер(мм.) | {tuberOrBro.applySize ? tuberOrBro.applySize : '-'}</div>
                <div> Дата(очитане) | {tuberOrBro.dateCheck ? DatesHelper.parseAndFormatDate(tuberOrBro.dateCheck): '-'} </div>
                <div> Размер отчитане(мм.) | {tuberOrBro.checkSize ?tuberOrBro.checkSize: '-'}</div>
                <div> Резултат | {tuberOrBro.tuberculosisCheckTypeID ? mapAnimalTuberBrucelType(tuberOrBro.tuberculosisCheckTypeID):'-'}</div>
                <div> Партида | {tuberOrBro.tuberculin ?tuberOrBro.tuberculin.tuberculinDate:'-'}</div>
            </div>
        )
    }

    const generateTableRows = () => {
        let mappedBruce = props.animal.animalBrucellosis.map((el) => { el['date'] = el.animalBrucellosisDate; return el });
        let mappedTuber = props.animal.animalTuberculosis.map((el) => { el['date'] = el.dateApply; return el });
        let data = [...mappedBruce, ...mappedTuber].sort((a, b) => {
            return Date.parse(a.date) - Date.parse(b.date);
        });

        return data.map((tuberOrBro, i) => {
            return (
                <tr key={i}>
                    <td>{tuberOrBro.hasOwnProperty('animalBrucellosisDate')  ? DatesHelper.parseAndFormatDate(tuberOrBro.animalBrucellosisDate) : DatesHelper.parseAndFormatDate(tuberOrBro.dateApply)}</td>
                    <td>{tuberOrBro.hasOwnProperty('animalBrucellosisDate') ? 'Бруцелоза' : 'Туберкулоза'}</td>
                    <td>
                        {tuberOrBro.hasOwnProperty('animalBrucellosisDate') ? bruceInfo(tuberOrBro) : tuberInfo(tuberOrBro)}
                    </td>
                </tr>
            );
        });
    }

    return (

        <table className="table table-custom table-dark">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Тип</th>
                    <th>Информация</th>
                </tr>
            </thead>
            <tbody>
                {generateTableRows()}
            </tbody>
        </table>
    );
}

export default TuberBrucelTable;