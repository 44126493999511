
import React from "react";
import Query from "../../data/query";
import { getTranslate } from 'react-localize-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { connect } from "react-redux";

class Enum extends React.Component {
    render = _ => 
        <Query auth={this.props.auth} query={ this.props.query }>
            {({ enums }) => {
            
            const res = enums.map(e => {
                e.textFriendly = this.props.translate(`${this.props.enumValue}.${e.name.toLowerCase()}`);
                return e; 
            });

            return <DropDownList 
                data={ res }
                defaultItem={{ textFriendly: this.props.label || "", isDefault: true}}
                textField="textFriendly"
                dataItemKey="value"
                value={this.props.value}
                onChange={ e => {
                    if(typeof this.props.onChange  === "function") {
                        this.props.onChange(e);
                    }
                }}
            />
            
        }}
        </Query>
}

export default connect(state => ({  translate: getTranslate(state.localize), auth: state.auth }))(Enum);