import React from 'react';
import {
  Chart,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartArea,
  ChartTooltip
} from '@progress/kendo-react-charts';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import DatesHelper from '../../../../../tools/DatesHelper';

const AdgChart = props => {

  const adgChartStyle = { style: 'step' };

  const adgChart = props.animalADG ? props.animalADG.map(a => a.animalANG) : [];
  const adgChartDates = props.animalADG ? props.animalADG
    .map(a => DatesHelper.parseAndFormatDate(a.aNGdate)) : [];

  return (
    <DashboardCard>
      <div className="card-heading">
        <h5>| <span className="text-success">СДП</span> | {props.animal.lID} | {props.animal.vID} | {props.animal.breed.breedShort} | {props.animal.ageInMonths} | {props.animal.lastWeight['weight']} |</h5>
        <hr />
      </div>
      <Chart>
        <ChartArea background="#f1f1f1" height="240px" />
        <ChartTooltip />
        <ChartValueAxis>
          <ChartValueAxisItem />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={adgChartDates} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem data={adgChart} type="line" color="#198CDA" style={adgChartStyle.style} />
        </ChartSeries>
      </Chart>
    </DashboardCard>
  );
};

export default AdgChart;