import { gql } from "apollo-boost";

export const BREED_QUERY_FOR_DDL =  gql`
{
    breeds {
        items {
            breedName
            breedsId
        }
    }
}`;