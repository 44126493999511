import React from "react";
import { Route, Switch } from 'react-router-dom';

import AnimalsMain from "./animals-main";
import Movements from "./movements/movements";
import AnimalsListWrapper from "./animals-list-wrapper";
import AnimalCardContainer from './animal-card';

const Animals = outherProps => {
  return (
    <main className="mb-5">
      <Switch>
        <Route path="/animals" exact render = { props => <AnimalsMain {...props} />} />
        <Route path="/animals/movement" component={Movements} />
        <Route path="/animals/list" render = { props => <AnimalsListWrapper {...props} {...outherProps} />} />
        <Route path="/animals/:id" render={props => <AnimalCardContainer animalId={+props.match.params.id} {...props} />} />
      </Switch>
    </main>
  )
};
export default Animals;