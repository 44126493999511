import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IndividualEntries from './entries/individual-entries/individual-entries';
import GroupEntries from './entries/group-entries/group-entries';
import MovementsMain from './movements-main';

import '../../../views/events/events.css';


const Movements = props => (
    <Switch>
        <Route path="/animals/movement/individual-entries" component={IndividualEntries} />
        <Route path="/animals/movement/group-entries" component={GroupEntries} />
        <Route path="/animals/movement" component={MovementsMain} />
    </Switch>
)

export default Movements;