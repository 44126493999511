import { gql } from "apollo-boost";

export const COLORS_QUERY_FOR_DDL =  gql`
{
    color{
        items { 
            colorID
            colorName
           }
       }
}`;