import React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Query from "../../../../data/query";
import {GET_ANIMAL_BCS} from '../../../../data/animal/animal-query';
import BcsTable from './events/bcs-table/bcs-table';
import AddBcsModal from './events/bcs-table/add-bcs-modal';

import NotesTable from './events/notes-table';

const EventsTab = props => {
  const id = props.currentAnimalId;
  return (
    <div className="tab">
      <div className="tab-head">
        <div className="d-flex align-items-baseline mb-4">
          <h3 className="mb-0">| СЪБИТИЯ |</h3>
          <div className="d-flex align-items-baseline ml-3">
            <button className="button-icon icon-color-success mr-3">
              <FontAwesomeIcon icon={'question-circle'} />
            </button>
            <strong>FR4433554050 | SB0674 | BAQ | КРАВА | 48 м. |</strong>
          </div>
        </div>
        <div className="tab-head-actions mb-3">
          <button className="button-icon icon-large mr-3">
            <FontAwesomeIcon icon={'file-pdf'} className="text-accent" />
          </button>
          <button className="button-icon icon-large">
            <FontAwesomeIcon icon={'print'} className="text-accent" />
          </button>
        </div>
      </div>
      <div className="table-inner mb-5">
        <p className="text-muted mb-1">| ВЕТЕРИНАРНИ | РЕПРОДУКЦИЯ | ДАННИ | ЗДРАВЕ | РАЗВЪДНИ | ТЕЛЕТА | ТЕГЛА |</p>
        <button className="btn btn-danger">ДОБАВИ СЪБИТИЕ</button>
        <form className="filters m-0">
          <small className="filters-title d-block text-accent my-3">| ФИЛТРИ |</small>
          <div className="d-flex">
            <div className="form-group mr-4">
              <label htmlFor="exampleInputEmail1">От дата</label>
              <DatePicker defaultValue={new Date()} />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">До дата</label>
              <DatePicker defaultValue={new Date()} />
            </div>
          </div>
          <div>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test1" />
              <label className="k-checkbox-label" htmlFor="test1">Общи</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test2" />
              <label className="k-checkbox-label" htmlFor="test2">Репродуктивни</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test3" />
              <label className="k-checkbox-label" htmlFor="test3">Ветеринарни</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test4" />
              <label className="k-checkbox-label" htmlFor="test4">Отелвания</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test5" />
              <label className="k-checkbox-label" htmlFor="test5">Движения</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test6" />
              <label className="k-checkbox-label" htmlFor="test6">Сървиси</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test7" />
              <label className="k-checkbox-label" htmlFor="test7">Телета</label>
            </label>
          </div>
          <small className="text-muted mb-1">История на събитията за FR4433554050</small>
        </form>
        <table className="table table-custom table-dark">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Събитие</th>
              <th>Информация за събитието</th>
              <th>Ветеринарен лекар /Отговорник</th>
              <th>Инструменти</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>07/09/17</td>
              <td>Бременност</td>
              <td></td>
              <td>Георги Великов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
            <tr>
              <td>09/10/18</td>
              <td>Бременност</td>
              <td></td>
              <td>Георги Великов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
            <tr>
              <td>04/06/19</td>
              <td>Бременност</td>
              <td></td>
              <td>Георги Великов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
            <tr>
              <td>15/06/19</td>
              <td>Аборт</td>
              <td></td>
              <td>Кирил Богданов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <NotesTable animalId={id} />
      <Query {...props} query={GET_ANIMAL_BCS} variables={{ id }}>
        {({ animal }) => {
          return (
            <div className="table-inner mb-5">
              <p className="text-muted mb-1">| OTC |</p>
              <AddBcsModal animalID={id} />
              <div className="d-flex justify-content-between align-items-baseline">
                <small className="text-muted mb-1">Cъбития за oценка на телесно състояние!</small>
                <div className="tab-head-actions">
                  <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                </div>
              </div>
              <BcsTable bCS={animal.bCS} />
            </div>
          );
        }}
      </Query>
    </div>
  )
};

export default EventsTab;