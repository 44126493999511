import { gql } from "apollo-boost";


const generateQuery = enumType => gql`{
    enums :${enumType} {
        name
        value
    }
}`;

export const COUNTRY_FROM = generateQuery("countryFrom");
export const ANIMAL_REGISTER = generateQuery("register");
export const ANIMAL_OTS = generateQuery("animalOTS");
export const ANIMAL_INPUT = generateQuery("typeInput");
export const ANIMAL_BREEDING_STATUS = generateQuery("breedingStatus");
export const ANIMAL_SEX = generateQuery("sexType");





