import { gql } from "apollo-boost";

export const ANIMALS_GRID = gql`
{
  animals {
    totalCount
    items {
      id: animalId
      breed {
        breedsId
        breedName
        breedShort
      }
      animalLocation{
        location{
          locationName
          locationID
          
        }
      }
      animalbCS: bCS {
        animalOTS
        bCSDate
      }
      breedingStatus
      typeInput
      register
      adgOverall
      animalADG {
        animalANG
        aNGdate
      }
      color {
        colorID
        colorName
      }
      countryFrom
      aClass{
        classID
        className
      }
      cowBirthCount
      sex
      lID
      vID
      breedCode
      dateOfBirth
      name
      comment
      weights {
        weight
        dateWeight
      }
      
      sire {
        lID
        breed {
          breedName
          breedShort
        }
      }
      dam {
        lID
        breed {
          breedName
        }
      }
    }
  }
}  
`;

export const GET_ANIMAL_EVENTS = gql`
  query AnimalEvents($id: ID!) {
    animal(id: $id) {
      events {
        birthEvents {
          animalEventTypeID
          animalID
          birthEventID
          dateCreate
          placenta
        }
        heatEvents{
          animalEventTypeID
          animalHeatID
          animalID
          date
          dateCreate
          heatDate
          heatCheck
        }
        pregnancyEvents{
          dateCreate
          date
          pregnancyDate
          pregnancyDays
          animalEventTypeID
        }
        weaningEvents{
          dateCreate
          weaningDate
          weaningEventID
          weaningWeight
          date
          dateCreate
          animalEventTypeID
        }
      }
    }
  }

`;
export const GET_ANIMAL_DETAILS = gql`
  query AnimalDetails($id: ID!) {
    animal(id: $id) {
      animalId
      lID
      vID
      name
      passportNumber
      dateOfBirth
      sex
      active
      breed {
        breedShort,
        breedName
        breedsId
      }
      aClass{
        className
        classID
      }
      children{
        sex
      }
      weights {
        weightID
        weight
        dateWeight
        weightType
        comment
      }
      adgOverall
      comment
      color {
        colorName
        colorID
      }
      sire {
        animalId
        lID
        breed {
          breedShort
        }
        breedComposition{
          breed{
            breedShort
          }
          breedPercent
        }
      }
      dam {
        animalId
        lID
        breed {
          breedShort
        }
        breedComposition{
          breed{
            breedShort
          }
          breedPercent
        }
      }
      breedingStatus
      cowBirthCount
      typeInput
      electronicId
      eIDType
      dryWetStatus
      register
      registrationDate
      herdBookOut
      fromEmbryoTransfer
      breedComposition{
        breedID
        animalBreedCompositionID
        breed{
          breedShort
        }
        breedPercent
      }
      dNAnumber
      dNAType
      hornStatusID
      countryFrom
      underSelection
      holdTo
      animalLocation {
        moveDate
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
              locationType
            }
          }
        }
      }
      vetis {
        animalVetisID
        animalVetisLID
        animalVetisEID
        animalVetisDOB
        animalVetisSex
        color{
          colorID
          colorName
        }
        animalVetisDamLID
        underSelection
      }
    }
  }
`;

export const ADD_WEIGHT = gql`
  mutation AddAnimalWeight($weightToAdd: animalWeightInput) {
    addAnimalWeight(animalWeight: $weightToAdd) {
      comment
      dateWeight
      weight
      weightID
      weightType
    }
  }
`;


export const GET_ANIMAL_ADG = gql`
query AnimalAdg($id: ID!) {
  animal(id: $id) {
    animalADG{
      aNGdate
      animalANG
    }
  }
}
`;

export const GET_ADJUSTED_WEIGHTS = gql`
query AdjustedWeights($id: ID!) {
  animal(id: $id) {
    adjustedBirthWeight
    adjustedWeaningWeight
    adjustedMatureWeight
    adjustedYearlingWeight
  }
}
`;

export const GET_ANIMAL_VACCINE = gql`
query AnimalVaccine($id: ID!) {
  animal(id: $id) {
    vaccine {
      animalVaccineDate
      animalVaccineDrug
      animalVaccineComment
      animalVaccineEndMonths
      animalVaccinePrice
    	animalVaccineDose
      animalVaccineKarentDays
      farmClientID
      animalID
      animalVaccineID
      vet {
        farmClientName
        farmClientsInType
      }
    }
  }
}
`;

export const GET_FARMCIENTS = gql`
{
  farmClients{
    items{
      farmClientID
      farmClientsInType
      farmClientName      
    }
  }
}
`;

export const UPDATE_ANIMAL_VACCINE = gql`
  mutation UpdateAnimalVaccine($animalVaccineUpdates: animalVaccineInput) {
    updateAnimalVaccine(animalVaccine: $animalVaccineUpdates) {
      animalID
      animalVaccineID
    }
  }
`;

export const ADD_ANIMAL_VACCINE = gql`
  mutation AddAnimalVaccine($animalVaccineAdd: animalVaccineInput) {
    addAnimalVaccine(animalVaccine: $animalVaccineAdd) {
      animalID
    }
  }
`;

export const DELETE_ANIMAL_VACCINE = gql`
  mutation DeleteAnimalVaccine($animalVaccineDelete: ID!) {
    deleteAnimalVaccine(animalVaccineID: $animalVaccineDelete) {
      animalVaccineID
    }
  }
`;

export const GET_ANIMAL_BCS = gql`
query AnimalBCS($id: ID!) {
  animal(id: $id) {
    bCS{
      animalID
      bCSID
      bCSDate
      animalOTS
      comment
      personInCharge{
        farmClientID
        farmClientName
      }
    }
  }
}
`;

export const ADD_ANIMAL_BCS = gql`
  mutation AddAnimalBCS($animalAddBCS: animalBCSInput) {
    addAnimalBCS(animalBCS: $animalAddBCS) {
      animalID
    }
  }
`;

export const UPDATE_ANIMAL_BCS = gql`
  mutation UpdateAnimalBCS($animalUpdateBCS: animalBCSInput) {
    updateAnimalBCS(animalBCS: $animalUpdateBCS) {
      animalID
      bCSID
    }
  }
`;

export const GET_ANIMAL_TUBERCULOSIS_BRUCELLOSIS = gql`
query AnimalTuberBrucel($id: ID!) {
  animal(id: $id) {
    animalTuberculosis{
      dateApply
      dateCheck
      applySize
      checkSize
      tuberculinDose
      tuberculosisCheckTypeID
      tuberculin{
        tuberculinDate
        tuberculinPackage
      }
    }
     animalBrucellosis{
      animalBrucellosisDate
      animalBrucellosisNumber
      tuberculosisCheckTypeID
    }
  }
}
`;

export const UPDATE_ANIMAL = gql`
  mutation UpdateAnimal($animalUpdates: animalInput) {
    updateAnimal(animal: $animalUpdates) {
      animalId
    }
  }
`;

export const GET_SIRES_AND_DAMS = gql`
query GetSiresAndDams{
  dams:animals(classType: DAM) {
    totalCount
    items {
      animalId
      lID
    }
  }
  sires:animals(classType: SIRE) {
    totalCount
    items {
      animalId
      lID
    }
  }
}
`;

export const ADD_ANIMAL_VETIS = gql`
mutation AddAnimalVetis($vetisUpdates: animalVetisInput) {
  addAnimalVetis(animalVetis: $vetisUpdates) {
    animalVetisID
    animalVetisLID
    animalVetisEID
    animalVetisDOB
    animalVetisSex
    color{
      colorID
      colorName
    }
    animalVetisDamLID
    underSelection
  }
}
`;

export const UPDATE_ANIMAL_VETIS = gql`
  mutation UpdateAnimalVetis($vetisUpdates: animalVetisInput) {
    updateAnimalVetis(animalVetis: $vetisUpdates) {
      animalVetisID
      animalVetisLID
      animalVetisEID
      animalVetisDOB
      animalVetisSex
      color{
        colorID
        colorName
      }
      animalVetisDamLID
      underSelection
    }
  }
`;

export const GET_ANIMAL_BREEDING_DATA = gql`
query AnimalBreedingData($id: ID!) {
  animal(id: $id) {
    animalFunctionality
    animalSkeleton
    animalMusculature
    animalTemperament
    animalMarbling
    sEUROP
    widthBack
    growthBack
    growthRibeye
    widthThigh
    heightWithers
    lengthBack
    lengthPelvic
    widthFlank
    widthPelvic
    frontLegs
    backLegs
    backLegsSide
  }
}
`;

export const UPDATE_ANIMAL_BREEDING_DATA = gql`
mutation UpdateAnimalBreedingData($breedingDataUpdates: animalInput) {
  updateAnimal(animal: $breedingDataUpdates) {
    animalFunctionality
    animalSkeleton
    animalMusculature
    animalTemperament
    animalMarbling
    sEUROP
    widthBack
    growthBack
    growthRibeye
    widthThigh
    heightWithers
    lengthBack
    lengthPelvic
    widthFlank
    widthPelvic
    frontLegs
    backLegs
    backLegsSide
  }
}
`;

export const GET_ANIMAL_NOTES = gql`
query AnimalNotes($id: ID!) {
  animal(id: $id) {
    notes{
      noteID
      note
      noteTopic
      dateNote
    }
  }
}
`;

export const ADD_NOTE = gql`
  mutation AddNote($noteToAdd: animalNoteInput) {
    addNoteAnimal(animalNote: $noteToAdd) {
      animalID
      dateNote
      note
      noteID
      noteTopic
    }
  }
`;

export const EDIT_NOTE = gql`
  mutation EditNote($noteToUpdate: animalNoteInput) {
    updateNoteAnimal(animalNote: $noteToUpdate) {
      animalID
      dateNote
      note
      noteID
      noteTopic
    }
  }
`;

export const GET_ANIMAL_PEDIGREE = gql`
query AnimalPedigree($id: ID!) {
  animal(id: $id) {
    animalId
    name
    dateOfBirth
    lID
    breedComposition {
      breedPercent
      breed{
        breedShort
      }
    }
    breed {
      breedShort
    }
    sire {
      animalId
      name
      dateOfBirth
      lID
      breedComposition {
        breedPercent
        breed{
        breedShort
      }
      }
      breed {
        breedShort
      }
      dam {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
          breedPercent
          breed{
        breedShort
      }
        }
        breed {
          breedShort
        }
        sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
      sire {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
         sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
    }
    dam {
      animalId
      name
      dateOfBirth
      lID
      breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
       dam {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
      sire {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
    }
  }
}`;

export const GET_ANIMAL_BREEDS = gql`
{
breeds{
  items{
    breedsId
    breedName
    breedShort
  }
}
}
`;

export const CREATE_UPDATE_BREED_COMPOSITION = gql`
  mutation CreateUpdateBreedComposittion($breedToCreateUpdate: [animalBreedCompositionInput]) {
    createUpdateBreedComposition(breedComposition: $breedToCreateUpdate) {
      animalID
      breedID
     }
   }
 `;


export const DELETE_WEIGHT = gql`
  mutation DeleteWeight($targetWeight: animalWeightInput) {
    deleteAnimalWeight(animalWeight: $targetWeight) {
      weightID
    }
  }
`;

export const UPDATE_WEIGHT = gql`
  mutation UpdateWeight($targetWeight: animalWeightInput) {
    updateAnimalWeight(animalWeight: $targetWeight) {
      weight
      weightID
      weightType
      dateWeight
    }
  }
`;