import React from 'react';


import '@progress/kendo-react-charts'
import '@progress/kendo-react-popup'
import '@progress/kendo-react-inputs'
import '@progress/kendo-react-intl'
import '@progress/kendo-data-query'
import '@progress/kendo-drawing'

import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
} from '@progress/kendo-react-charts';


const RodeoChartCalvesProduction = props => {
    // Data for demo
    const seriesData = [{
        product: "Телета 2017",
        sales: 100,
        color: '#198CDA'
    }, {
        product: "Крави 2017",
        sales: 120,
        color: '#EE624B'
    }, {
        product: "Телета 2018",
        sales: 80,
        color: '#198CDA'
    },
    {
        product: "Крави 2018",
        sales: 100,
        color: '#EE624B'
    },
    {
        product: "Телета 2019",
        sales: 65,
        color: '#198CDA'
    },
    {
        product: "Крави 2019",
        sales: 65,
        color: '#EE624B'
    }];
    return (
        <div>
            <h5 className="text-center mb-4" >Производство телета '17-'19</h5>
            <Chart>
                <ChartArea background="#f1f1f1" height="315px" />
                <ChartSeries>
                <ChartSeriesItem data={seriesData} type="column" field="sales" categoryField="product" />
                </ChartSeries>
            </Chart>
        </div>
    )
}

export default RodeoChartCalvesProduction;
