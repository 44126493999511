import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Query from "../../../../data/query";
import { GET_ANIMAL_VACCINE,GET_ANIMAL_TUBERCULOSIS_BRUCELLOSIS } from '../../../../data/animal/animal-query';
import VaccineTable from './health/vaccine-table/vaccine-table';
import AddVaccineModal from './health/vaccine-table/add-vaccine-modal';
import './health/health-tab.css';
import TuberBrucelTable from './health/tuber-brucel-table/tuber-brucel-table';

const HealthTab = props => {

  const id = props.currentAnimalId;
  return (
    <div className="tab">
      <div className="tab-head">
        <div className="d-flex align-items-center mb-4">
          <h3 className="mb-0">| ЗДРАВЕ |</h3>
          <div className="d-flex ml-3">
            <strong className="ml-2 text-success">| ЗДРАВА |</strong>
          </div>
        </div>
      </div>
      <Query {...props} query={GET_ANIMAL_TUBERCULOSIS_BRUCELLOSIS} variables={{ id }}>
        {({ animal }) => {
          return (
            <div className="table-inner mb-5">
              <p className="text-muted mb-1">| ДПП | ТУБЕРКУЛОЗА | БРУЦЕЛОЗА | ВНД | СИН ЕЗИК |</p>
              <button className="btn btn-danger">ДОБАВИ СЪБИТИЕ</button>
              <div className="d-flex justify-content-between align-items-baseline">
                <small className="text-muted mb-1">Тук добавяте събития по държавна профилактична програма</small>
                <div className="tab-head-actions">
                  <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                </div>
              </div>
              <TuberBrucelTable animal={animal} />
            </div>
          );
        }}
      </Query>
      <Query {...props} query={GET_ANIMAL_VACCINE} variables={{ id }}>
        {({ animal }) => {
          return (
            <div className="table-inner mb-5">
              <p className="text-muted mb-1">| Ветеринарни | Лечение | Диагностика | Манипулации |</p>
              <AddVaccineModal animalID={id} />
              <div className="d-flex justify-content-between align-items-baseline">
                <small className="text-muted mb-1">Тук добавяте всички ветеринарни събития!</small>
                <div className="tab-head-actions">
                  <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                </div>
              </div>
              <VaccineTable vaccine={animal.vaccine} />
            </div>
          );
        }}
      </Query>
    </div>
  )
};

export default HealthTab;