import { includes } from "lodash";

export const filterByBirthDate = ([items, filter]) => {
    
    return !!filter.BornBefore && !!filter.BornUntil ?
        [items.filter(it => 
            Date.parse(it.dateOfBirth) <= Date.parse(filter.BornUntil.toLocaleDateString()) 
            && Date.parse(it.dateOfBirth) >= Date.parse(filter.BornBefore.toLocaleDateString())
        ), filter] : [items, filter];
}

export const filterByWeight = ([items, filter]) => {
    return !!filter.WeigthFrom && !!filter.WeigthTo ?  [items.filter(it => it.weight >= filter.WeigthFrom && it.weight <= filter.WeigthTo), filter] : [items, filter];
}

export const filterByAgeInMonths = ([items, filter]) => {
    return !!filter.AgeInMonthsFrom && !!filter.AgeInMonthsTo ?
      [items.filter(it => it.ageInMonths >= filter.AgeInMonthsFrom && it.ageInMonths <= filter.AgeInMonthsTo), filter] : [items, filter];
}

export const filterAClass = ([items, filter]) => {
    return !!filter.aClassSelection ? [items.filter(it => (it.aClass || { }).classID == filter.aClassSelection.classID), filter] : [items, filter];
}

export const filterBreed = ([items, filter]) => {
    return !!filter.breedSelection ? [items.filter(it => (it.breed || { }).breedsId == filter.breedSelection.breedsId), filter] : [items, filter];
}

export const filterSex = ([items, filter]) => {
    return !!filter.sexSelection ? [items.filter(it => it.sex == filter.sexSelection.text), filter] : [items, filter];
}

export const filterColor = ([items, filter]) => {
    return !!filter.colorSelection ? [items.filter(it => (it.color || { }).colorID == filter.colorSelection.colorID), filter] : [items, filter];
}

export const filterGeneral = ([items, filter]) => {
    return !!filter.generalSelection ? [items.filter(it => {
        const name = (it.name || "").toLowerCase();
        const filterValue = filter.generalSelection.toLowerCase();
        const breedName = (it.breed || { }).breedShort.toLowerCase();
        const lID = (it.lID || "").toLowerCase();
        
        return includes(name, filterValue) || includes(lID, filterValue) || includes(breedName, filterValue);
    }), filter] : [items, filter];
}
export const filterCowBirthCount = ([items, filter]) => {
    return !!filter.cowBirthCountSelection ? [items.filter(it => it.cowBirthCount == filter.cowBirthCountSelection.value), filter] : [items, filter];
}

export const filterBoxes = ([items, filter]) => {
    return !!filter.filterBoxSelection ? [items.filter(it => ((it.location || { }).location || { }).locationID == filter.filterBoxSelection.value), filter] : [items, filter];
}

export const barns = ([items, filter]) => {
    return !!filter.barnSelection ? [items.filter(it => ((it.location || { }).location || { }).locationID == filter.barnSelection.value), filter] : [items, filter];
}
export const countryFrom = ([items, filter]) => {
    return !!filter.countryFromSelection ? [items.filter(it => (it.countryFrom || "").toUpperCase()  == filter.countryFromSelection.name.toUpperCase()), filter] : [items, filter];
}

export const animlaRegisterFilter = ([items, filter]) => {
    return !!filter.animalRegisterSelection ? [items.filter(it => (it.register || "").toUpperCase()  == filter.animalRegisterSelection.name.toUpperCase()), filter] : [items, filter];
}

export const animalOTSFilter = ([items, filter]) => {
    return !!filter.otsSelection ? [items.filter(it => (it.currentAnimalBCS || { }).animalOTS  == filter.otsSelection.name.toUpperCase()), filter] : [items, filter];
}

export const animalInputTypeFilter = ([items, filter]) => {
    return !!filter.animalInputTypeSelection ? [items.filter(it => (it.typeInput || "").toUpperCase()  == filter.animalInputTypeSelection.name.toUpperCase()), filter] : [items, filter];
}

export const animalBreedingStatusFilter = ([items, filter]) => {
    return !!filter.animalRepStatusSelection ? [items.filter(it => (it.breedingStatus || "").toUpperCase()  == filter.animalRepStatusSelection.name.toUpperCase()), filter] : [items, filter];
}


