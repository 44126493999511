import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Query from '../../../../../data/query';
import { GET_ANIMAL_NOTES } from '../../../../../data/animal/animal-query';
import DatesHelper from '../../../../../tools/DatesHelper';
import AddNoteModal from './add-note-modal';
import UpdateNoteModal from './update-note-modal';

const NotesTable = (props) => {
 const id = props.animalId;
 let generateTableRows =(notes)=> {
    return notes.map((n, i) =>
      <tr key={i}>
        <td>{DatesHelper.parseAndFormatDate(n.dateNote)}</td>
        <td>{n.noteTopic}</td>
        <td>{n.note}</td>
        <td>
          <div className="d-flex justify-content-center">
            <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
            <UpdateNoteModal currentNote={n} animalId={id} />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <div className="table-inner mb-5">
      <p className="text-muted mb-1">| БЕЛЕЖКИ |</p>
      <AddNoteModal animalId={id} />
      <Query query={GET_ANIMAL_NOTES} variables={{ id: id }}>
        {({ animal: { notes } }) => {
          return <div>
            {notes && notes.length > 0 ?
              <div className="d-flex justify-content-between align-items-baseline">
                <small className="text-muted mb-1">Тук добавяте бележки</small>
                <div className="tab-head-actions">
                  <button className="button-icon"><FontAwesomeIcon icon={'file-pdf'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'print'} /></button>
                  <button className="button-icon"><FontAwesomeIcon icon={'file-excel'} /></button>
                </div>
              </div>
              :
              ''
            }
            {notes && notes.length > 0 ?
              <table className="table table-custom table-dark">
                <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Тема</th>
                    <th>Бележка</th>
                    <th>Инструменти</th>
                  </tr>
                </thead>
                <tbody>
                  {generateTableRows(notes)}
                </tbody>
              </table>
              :
              <p className="text-muted">Няма бележки за това животно.</p>
            }
          </div>
        }}
      </Query>
    </div>
  )
}

export default NotesTable;