import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../data/mutation';
import { CREATE_UPDATE_BREED_COMPOSITION, GET_ANIMAL_DETAILS } from '../../../../../data/animal/animal-query';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import './data-tab.css';
import { generateBreedCompositionString } from '../../animal-utilities';



class BreedCompositModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            animal: props.animal,
            breeds: props.breeds,
            changes: {
                animalID: props.animal.animalId,
                breedID: props.animal.breed.breedsId,
                breedPercent: 1,
                breedComposition: props.animal.breedComposition,
                isChanges: false
            },
            refreshText: props.refreshText,
            initialState: JSON.parse(JSON.stringify(props.animal)),
            submitDisabled: true,
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.addNewBreedComposit = this.addNewBreedComposit.bind(this);
        this.selectedBreed = this.selectedBreed.bind(this);
        this.deleteBreedComposite = this.deleteBreedComposite.bind(this);
        this.checkDuplicationAndPercents = this.checkDuplicationAndPercents.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });

        if (!this.state.visible) {
            this.resetState();
        }
    }


    handleFormChange(e) {
        let objCopy = { ...this.state.animal };
        let changes = { ...this.state.changes };

        if ("breedsId" === e.target.name.split('-')[0]) {
            if (this.state.animal.breedComposition.length > 0) {
                for (let i = 0; i < objCopy.breedComposition.length; i++) {
                    if (objCopy.breedComposition[i].breedID !== e.target.value.breedsId && e.target.props.id === i + 1) {
                        objCopy.breedComposition[i].breedID = e.target.value.breedsId;
                        objCopy.breedComposition[i].breed.breedShort = e.target.value.breedShort;
                    }
                }
                changes['breedComposition'] = objCopy.breedComposition;
                changes['isChanges'] = true;
            } else {
               if (objCopy['breed']) {
                objCopy['breed']['breedsId'] = e.target.value.breedsId;
                objCopy['breed']['breedName'] = e.target.value.breedName;
                objCopy['breed']['breedShort'] = e.target.value.breedShort;

               }else{
                   objCopy['breed']={
                    breedsId: e.target.value.breedsId,
                    breedName:e.target.value.breedName,
                    breedShort:e.target.value.breedShort
                   }
               }
               changes['isChanges'] = true;
               changes['breedID'] = e.target.value.breedsId;
             
            }
        } else if ('breedPercent' === e.target.name.split('-')[0]) {
            if (this.state.animal.breedComposition.length > 0) {
                for (let i = 0; i < objCopy.breedComposition.length; i++) {
                    if (objCopy.breedComposition[i].breedID === parseInt(e.target.name.split('-')[1]) && parseInt(e.target.props.id.split('-')[1]) === i + 1) {
                        objCopy.breedComposition[i].breedPercent = e.target.value;
                    }
                }
                changes['breedComposition'] = objCopy.breedComposition;
                changes['isChanges'] = true;
            } else {

                changes['isChanges'] = true;
                changes['breedPercent'] = e.target.value;
            }
        }

        this.setState({
            animal: objCopy,
            changes: changes,
            submitDisabled: !changes['isChanges']
        });
        this.checkDuplicationAndPercents(objCopy);
    }

    handleSubmit(e, addpUpdateTrigger) {
        e.preventDefault();
        let result = [];

        if (this.state.changes.isChanges) {
            if (this.state.changes.breedComposition.length > 0) {
                result = this.state.changes.breedComposition.map((el) => {
                    return {
                        animalID: this.state.animal.animalId,
                        breedID: el.breedID,
                        breedPercent: el.breedPercent
                    }
                });
            } else {
                result = [{
                    animalID: this.state.changes.animalID,
                    breedID: this.state.changes.breedID,
                    breedPercent: this.state.changes.breedPercent,
                }]
            }

            this.state.refreshText(generateBreedCompositionString(this.state.animal));

            addpUpdateTrigger({
                variables:
                {
                    breedToCreateUpdate: result
                }
            });
        }

        this.setState({
            initialState: this.state.animal
        });
        this.resetState();
    }

    selectedBreed(curBreedId) {
        const selectedBreed = this.state.breeds.find((breedMap) => {
            return breedMap.breedsId === curBreedId;
        });
        return selectedBreed;
    }



    printTextBoxes() {
        let keyIndex = 0;
        if (this.state.animal.breedComposition.length > 0) {
            let result = this.state.animal.breedComposition.map((breed) => {
                keyIndex++
                return (
                    <div key={keyIndex} >
                        <div className="form-group" style={{ width: '60%', display: 'inline-block', marginRight: '10px' }} >
                            <label>Порода</label>
                            <DropDownList
                                style={{ width: '100%' }}
                                data={this.state.breeds}
                                dataItemKey="breedsId"
                                textField="breedName"
                                id={keyIndex}
                                name={`breedsId-${breed.breedID}`}
                                value={this.selectedBreed(breed.breedID)}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group" style={{ width: '25%', display: 'inline-block' }}>
                            <label>Процент</label>
                            <NumericTextBox
                                format="p"
                                value={breed.breedPercent}
                                min={0}
                                max={1}
                                step={0.1}
                                id={`breedPercent-${keyIndex}`}
                                onChange={this.handleFormChange}
                                name={`breedPercent-${breed.breedID}`}
                            />
                        </div >
                        <div className="form-group" style={{ width: '30px', display: 'inline-block' }}>
                            <button className="button-icon" data-index={keyIndex} onClick={event => this.deleteBreedComposite(event.currentTarget)}><FontAwesomeIcon icon={'trash'} /></button>
                        </div>

                    </div>
                );
            })
            return (
                <div>{result}</div>
            );
        } else {

            return (
                <div style={{display: this.state.animal.breed ? 'block':'none'}}>
                    <div className="form-group" style={{ width: '60%', display: 'inline-block', marginRight: '10px' }} >
                        <label>Порода</label>
                        <DropDownList
                            style={{ width: '100%' }}
                            data={this.state.breeds}
                            dataItemKey="breedsId"
                            textField="breedName"
                            name="breedsId"
                            value={this.state.animal.breed ?this.selectedBreed(this.state.animal.breed.breedsId) :''}
                            onChange={this.handleFormChange}
                        />
                    </div>
                    <div className="form-group" style={{ width: '25%', display: 'inline-block' }}>
                        <label>Процент</label>
                        <NumericTextBox
                            format="p"
                            defaultValue={1}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={this.handleFormChange}
                            name="breedPercent"
                        />
                    </div>
                    <div className="form-group" style={{ width: '30px', display: 'inline-block' }}>
                        <button className="button-icon" data-index={keyIndex} onClick={event => this.deleteBreedComposite(event.currentTarget)}><FontAwesomeIcon icon={'trash'} /></button>
                    </div>
                </div>
            );
        }
    }


    addNewBreedComposit() {
        let objCopy = { ...this.state.animal };
        objCopy.breedComposition.push(
            {
                breedID: 1,
                breedPercent: 0,
                breed: {
                    breedShort: 'AAN'
                }
            }
        );
        this.setState({
            animal: objCopy
        });

        this.checkDuplicationAndPercents(objCopy);
    }

    deleteBreedComposite(currentTarget) {
        if (this.state.animal.breedComposition.length > 0) {
            const indexToDelete = currentTarget.getAttribute('data-index');
            const objCopy = { ...this.state.animal };
            const changes = { ...this.state.changes };
            const arrayCopy = objCopy.breedComposition.slice();
            arrayCopy.splice(indexToDelete - 1, 1);
            objCopy.breedComposition = arrayCopy;
            changes['breedComposition'] = objCopy.breedComposition;
            changes['isChanges'] = true;
            this.setState({
                animal: objCopy,
                submitDisabled: false,
                changes: changes
            });
            
            this.checkDuplicationAndPercents(objCopy);
        }else{
            const objCopy = { ...this.state.animal };
            const changes = { ...this.state.changes };
            changes['isChanges'] = true;
            objCopy.breed = null;
            this.setState({
               animal:objCopy,
               changes:changes
            });
            
        }
   
    }

    checkDuplicationAndPercents(objCopy = null) {
        let breedCompositions = objCopy ? objCopy.breedComposition : this.state.animal.breedComposition;
        const unique = [...new Set(breedCompositions.map(a => a.breedID))];

        let result = 0;
        for (let i = 0; i < breedCompositions.length; i++) {
            result = (result * 100 + breedCompositions[i].breedPercent * 100) / 100
        }
     
        if (unique.length < breedCompositions.length) {
            this.setState({
                submitDisabled: true
            })
        } else if (result !== 1) {
            this.setState({
                submitDisabled: true
            })
        }
    }

    resetState() {
        this.setState({
            animal: JSON.parse(JSON.stringify(this.state.initialState)),
            changes: {
                animalID: this.state.initialState.animalId,
                breedID: this.state.initialState.breed ? this.state.initialState.breed.breedsId : '',
                breedPercent: 1,
                breedComposition: JSON.parse(JSON.stringify(this.state.initialState.breedComposition)),
                isChanges: false
            },
            submitDisabled: true
        });
    }

    render() {
        return (
            <Mutation mutation={CREATE_UPDATE_BREED_COMPOSITION} awaitRefetchQueries={true}
                refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.state.animal.animalId } }]}
                onCompleted={() => this.toggleDialog()}
            >
                {(addpUpdateTrigger) => (
                    <div className="d-inline-block float-right breed-composit-modal">
                        <button className="button-icon" onClick={this.toggleDialog}><FontAwesomeIcon icon={'pen'} /></button>
                        {this.state.visible && <Dialog className='health-modal' title={"Редактирай Породен композит"} onClose={this.toggleDialog} minWidth='700px' height="70%">
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    <button onClick={this.addNewBreedComposit} className="btn btn-success mb-3">Добави</button>
                                    {this.printTextBoxes()}
                                    <div className="d-flex">
                                        <button type="submit" disabled={this.state.submitDisabled} onClick={e => this.handleSubmit(e, addpUpdateTrigger)} className="btn btn-success mr-3">Запази</button>
                                        <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                    </div>
                                </Col>
                            </Row>
                        </Dialog>}
                    </div>
                )}
            </Mutation>
        );
    }
}

export default BreedCompositModal;