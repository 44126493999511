import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventsMain from './events-main';
import EventsCalfs from './events-calfs';
import EventsShared from './events-shared';
import EventsCalving from './events-calving';
import EventsFeeding from './events-feeding';
import EventsVeterinary from './events-veterinary';

import './events.css';

const Events = props => (
  <main className="mb-5">
    <Switch>
      <Route path="/events" exact component={EventsMain} />
    </Switch>
    <Switch>
      <Route path="/events/calfs" exact component={EventsCalfs} />
    </Switch>
    <Switch>
      <Route path="/events/shared" exact component={EventsShared} />
    </Switch>
    <Switch>
      <Route path="/events/calving" exact component={EventsCalving} />
    </Switch>
    <Switch>
      <Route path="/events/feeding" exact component={EventsFeeding} />
    </Switch>
    <Switch>
      <Route path="/events/veterinary" exact component={EventsVeterinary} />
    </Switch>
  </main>
);

export default Events;