import {
    HORN_STATUSES, BREEDING_STATUSES, WEIGHT_TYPES, TYPE_INPUTS,ANIMAL_OTS_TYPES,ANIMAL_TUBERCULOSIS_BRUCELLOSIS_TYPES
} from "../../../tools/translation-constants";

export const mapBreedingStatus = ({ breedingStatus }) => {
    return extractTranslation(
        BREEDING_STATUSES, breedingStatus,
        `Unsupported breeding status: ${breedingStatus}`);
}


export const mapWeightType = weightEnumVal => {
    return extractTranslation(
        WEIGHT_TYPES, weightEnumVal,
        `Unsupported weight type: ${weightEnumVal}`);
}

export const mapHornStatus = hornStatus => {
    return extractTranslation(
        HORN_STATUSES, hornStatus,
        `Unsupported horn status: ${hornStatus}`);
}

export const mapTypeInput = ({ typeInput }) => {
    return extractTranslation(
        TYPE_INPUTS, typeInput,
        `Unsupported type input: ${typeInput}`);
}

export const mapAnimalTuberBrucelType = typeInput => {
    return extractTranslation(
        ANIMAL_TUBERCULOSIS_BRUCELLOSIS_TYPES, typeInput,
        `Unsupported OTS type: ${typeInput}`);
}

export const mapAnimalOTSType = typeInput => {
    return extractTranslation(
        ANIMAL_OTS_TYPES, typeInput,
        `Unsupported OTS type: ${typeInput}`);
}

const extractTranslation = (collection, enumValue, errorMessage) => {
    const translation = collection.find(o => o.id === enumValue);
    if (!translation) {
        throw new Error(errorMessage);
    }
    return translation.text;
}