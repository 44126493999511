import React from 'react';
import { Row, Col } from 'reactstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import Mutation from '../../../../../data/mutation';
import CustomComboBox from './custom-combo-box';
import { UPDATE_ANIMAL, GET_ANIMAL_DETAILS, GET_ANIMAL_BREEDS } from '../../../../../data/animal/animal-query';

import {
  SEXES, BOOLEANS, COUNTRIES, DNA_TYPES,
  EID_TYPES, REGISTERS, HORN_STATUSES
} from '../../../../../tools/translation-constants';
import Query from '../../../../../data/query';
import BreedCompositModal from './breed-composit-modal';

export default function () {
  return (
    <Mutation mutation={UPDATE_ANIMAL} awaitRefetchQueries={true}
      refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.props.currentAnimal.animalId } }]}
      onCompleted={() => this.toggleEditing()}
    >
      {(updateAnimalTrigger) => (
        <form onSubmit={e => this.handleSubmit(e, updateAnimalTrigger)}>
          <div className="action-buttons">
            <button type="button" className="btn btn-danger" onClick={this.toggleEditing}>{this.state.editing ? 'ОТКАЖИ' : 'РЕДАКТИРАЙ'}</button>
            {this.state.editing ? <button disabled={Object.keys(this.state.changes).length <= 1} type="submit" className="btn btn-success">ЗАПИШИ</button> : ''}
            {/* <button type="button" className="btn btn-dark">ПРЕМАРКИРАЙ</button> */}
          </div>
          <fieldset disabled={!this.state.editing}>
            <Row>
              <Col lg="2">
                <div className="form-group">
                  <label>ВЕТИС №</label>
                  <Input name="lID" onChange={this.handleChange} value={this.state.animalData.lID} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>ВИН №</label>
                  <Input name="vID" onChange={this.handleChange} value={this.state.animalData.vID} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>EID №</label>
                  <Input name="electronicId" onChange={this.handleChange} value={this.state.animalData.electronicId} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Вид EID</label>
                  <DropDownList name="eIDType" onChange={this.handleChange}
                    data={EID_TYPES} textField="text" dataItemKey="id"
                    value={this.state.animalData.eIDType} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Име</label>
                  <Input name="name" onChange={this.handleChange} value={this.state.animalData.name} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Паспорт №</label>
                  <Input name="passportNumber" onChange={this.handleChange} value={this.state.animalData.passportNumber} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Дата на раждане</label>
                  <DatePicker name="dateOfBirth" onChange={this.handleChange} format="dd.MM.yyyy"
                    value={this.state.animalData.dateOfBirth} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Пол</label>
                  <DropDownList name="sex" onChange={this.handleChange} data={SEXES} textField="text"
                    dataItemKey="id" value={this.state.animalData.sex} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Категория</label>
                  <DropDownList name="aClass" onChange={this.handleChange} data={this.props.aClasses['items']}
                    textField="className" dataItemKey="classID" value={this.state.animalData.aClass}
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Порода</label>
                  <DropDownList name="breed" onChange={this.handleChange}
                    data={this.props.breeds['items']} textField="breedName" dataItemKey="breedsId" value={this.state.animalData.breed}
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <Query query={GET_ANIMAL_BREEDS}>
                  {(breeds) => {
                    return (<div className="form-group">
                      <label>Породен композит</label>
                      <span>{this.state.animalData.formattedBreedComposition}</span>
                      {this.state.editing && <BreedCompositModal refreshText={this.refreshBreedComposition} animal={this.state.animalData} 
                                                                 breeds={breeds.breeds.items}/>}
                    </div>)
                  }
                  }
                </Query>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Рога</label>
                  <DropDownList name="hornStatusID" onChange={this.handleChange}
                    data={HORN_STATUSES} textField="text" dataItemKey="id"
                    value={this.state.animalData.hornStatusID} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Цвят</label>
                  <DropDownList name="color" onChange={this.handleChange}
                    data={this.props.color['items']} textField="colorName" dataItemKey="colorID" value={this.state.animalData.color}
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Майка</label>
                  <CustomComboBox name="damID" onChange={this.handleChange} value={this.state.animalData.damID}
                    data={this.props.dams.items} textField="lID" dataItemKey="animalId"
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Баща</label>
                  <CustomComboBox name="sireID" onChange={this.handleChange} value={this.state.animalData.sireID}
                    data={this.props.sires.items} textField="lID" dataItemKey="animalId"
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Произход държава</label>
                  <DropDownList name="countryFrom" onChange={this.handleChange}
                    data={COUNTRIES} textField="text" dataItemKey="id" value={this.state.animalData.countryFrom} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Цена на придобиване</label>
                  <Input disabled={true} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Дата на придобиване {this.props.currentAnimal.registrationDate}</label>
                  <DatePicker name="registrationDate" onChange={this.handleChange}
                    format="dd.MM.yyyy" defaultValue={this.state.animalData.registrationDate}
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label className="d-flex flex-column">Развъдчик</label>
                  <DropDownList className="w-100" defaultValue="" disabled={true} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Развъден регистър ІBGІ</label>
                  <DropDownList name="register" onChange={this.handleChange}
                    data={REGISTERS} textField="text" dataItemKey="id"
                    value={this.state.animalData.register} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Племенен регистър 2*</label>
                  <Input name="herdBookOut" onChange={this.handleChange} value={this.state.animalData.herdBookOut} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>ДНК №</label>
                  <Input name="dNAnumber" onChange={this.handleChange} value={this.state.animalData.dNAnumber} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>ДНК Тип</label>
                  <DropDownList name="dNAType" onChange={this.handleChange}
                    data={DNA_TYPES} textField="text" dataItemKey="id"
                    value={this.state.animalData.dNAType} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>От ембрио-трансфер</label>
                  <DropDownList name="fromEmbryoTransfer" onChange={this.handleChange}
                    data={BOOLEANS} textField="text" dataItemKey="id" value={this.state.animalData.fromEmbryoTransfer}
                    disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Сурогатна майка</label>
                  <Input disabled={true} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>"Под СК"</label>
                  <DropDownList name="underSelection" onChange={this.handleChange}
                    data={BOOLEANS} textField="text" dataItemKey="id"
                    value={this.state.animalData.underSelection} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Задържане до</label>
                  <DatePicker name="holdTo" onChange={this.handleChange}
                    format="dd.MM.yyyy" value={this.state.animalData.holdTo} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Бележка</label>
                  <textarea className="k-textarea" name="comment" onChange={this.handleChange} value={this.state.animalData.comment} ></textarea>
                </div>
              </Col>
            </Row>
          </fieldset>
        </form>
      )}
    </Mutation>
  );
}