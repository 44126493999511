
import React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';

const DocumentsTab = props => {
  const options = [1, 2, 3, 4, 5];
  return (
    <div className="tab">
      <form>
        <Row>
          <Col lg='12'>
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| ДОКУМЕТИ |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Документация по придобиването!</p>
              </div>
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Паспорт №</label>
              <Input />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Пътен лист №</label>
              <Input />
            </div>
          </Col>
          <Col lg='4'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Отговорник</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
        </Row>
        <TabNavButtons  parentProps ={props}/>
      </form>

    </div>
  )
};

export default DocumentsTab;