import React from 'react';
import { Route, Switch } from "react-router-dom";

import GroupDataTab from './tabs/group-data-tab';
import GroupAndLocationTab from './tabs/group-and-location-tab';
import OwnershipTab from './tabs/ownership-tab';
import DocumentsTab from './tabs/documents-tab';
import TableTab from './tabs/table-tab';
import EntriesNavLinks from '../entries-nav-links';
import Breadcrumbs from '../../../../../layout/breadcrumbs';

import '../../../animal-card.css';



const GroupEntries = props => {

    // Arrays used for demo purpose
    const tabs = {
        links: [
            '/animals/movement/group-entries',
            '/animals/movement/group-entries/group-and-location',
            '/animals/movement/group-entries/ownership',
            '/animals/movement/group-entries/documents',
            '/animals/movement/group-entries/table',
        ],
        titles: [
            'Групови данни',
            'Групи и Локация',
            'Собственост',
            'Документи',
            'Таблица',
        ]
    };
    
    const breadcrumbs = [{ title: 'Животни', href: '/animals' }, { title: 'Движения', href:'/animals/movement' }, { title: 'Групово вписване' }];

    return (
        <main>
            <div className='container-fluid'>
            <Breadcrumbs pathLinks={breadcrumbs} />
            </div>

            <div className="main-nav small-nav red-colored container-fluid">
                 <EntriesNavLinks tabs={tabs} />
            </div>

            <div className="container-fluid">
                <Switch>
                    <Route path={tabs.links[1]} render={props => {
                        return <GroupAndLocationTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[2]} render={props => {
                        return <OwnershipTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[3]} render={props => {
                        return <DocumentsTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[4]} render={props => {
                        return <TableTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[0]} render={props => {
                        return <GroupDataTab props={props} tabsLinks={tabs.links} />
                    }} />
                </Switch>
            </div>
        </main>
    );
}

export default GroupEntries;