import React, { Component } from "react";
import logo from "../../assets/img/RODEO_White.png";
import search from "../../assets/img/icons/search.png";
import { NavLink } from "react-router-dom";

export default class Header extends Component {

    handleMenuButtonClick() {
        this.props.onMenuToggle();
    }

    render = () => {
        return (
            <header id="header">
                <div className="container-fluid search-top d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <button type="button" id="menu-btn" onClick={this.handleMenuButtonClick.bind(this)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="company-info text-center">
                            <figure className="company-logo">
                                <img src={logo} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className="search-header">

                        <label className="sr-only" htmlFor="search">Търси</label>
                        <div className="input-group align-items-center">
                            <input type="text" className="form-control" id="search" placeholder="Търси" />
                            <button type="submit" className="btn input-group-prepend">
                                <img src={search} alt="" />
                            </button>
                        </div>
                    </div>

                </div>
                <div className="main-nav container-fluid">
                    <ul className="nav nav-pills nav-fill font-weight-bold text-uppercase">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/animals">животни</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/events">събития</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/reports">отчети</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/map">карта</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/calendar">календар</NavLink>
                        </li>
                    </ul>
                </div>
            </header>

        )
    }
}