import React from 'react';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import { mapWeightType } from '../../animal-mapping-functions';
import DatesHelper from '../../../../../tools/DatesHelper';
import DeleteWeightModal from './delete-weight-modal';
import EditWeightModal from './edit-weight-modal';


const WeightsTable = props => {

  const generateControlledWeightsTable = () => {
    return props.animal.weights.map((weightObj, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(weightObj.dateWeight)}</td>
          <td>{weightObj.weight}</td>
          <td>{mapWeightType(weightObj.weightType)}</td>
          <td>{weightObj.comment}</td>
          <td className="text-center">
            <DeleteWeightModal animalID={props.animal.animalId} weightID={weightObj.weightID} />
            <EditWeightModal animalID={props.animal.animalId} currentWeight={weightObj}/>
          </td>
        </tr>
      );
    });
  }

  return (
    <DashboardCard isLight={true}>
      <div className="card-heading">
        <h5>| <span className="text-success">ТЕГЛА</span> | {props.animal.lID} | {props.animal.vID} | {props.animal.breed.breedShort} | {props.animal.ageInMonths} | {props.animal.lastWeight['weight']} |</h5>
        <hr />
      </div>
      <table className="table table-custom table-light">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Тегло</th>
            <th>Вид</th>
            <th>Пояснение / Бележка</th>
            <th>Инструменти</th>
          </tr>
        </thead>
        <tbody>
          {generateControlledWeightsTable()}
        </tbody>
      </table>
    </DashboardCard>
  );
}

export default WeightsTable;