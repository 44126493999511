import React from "react";
import { useMutation } from '@apollo/react-hooks';


export default ({ children, ...props }) => {
  const [trigger, { loading, error, data }] = useMutation(props.mutation, { ...props });
  if (loading) return <p>Loading...</p>;

  if (error) {
    if (error.networkError.statusCode === 401) {
      props.auth.login();
      return <div>Redirecting...</div>
    }

    return <div>Error :(</div>
  }

  return children(trigger, { data });
}
