import React from 'react';
import { Row, Col } from 'reactstrap';

import growth from '../../../assets/img/icons/ICON5.png';
import calf from '../../../assets/img/icons/ICON1.png';

import './events.css';
import Breadcrumbs from '../../layout/breadcrumbs';



const EventsCalfs = props => {
  const breadcrumbs = [{ title: 'Събития', href:'/events' }, { title: 'Телета събития' }];

  return (
    <div className="container-fluid">
      <Breadcrumbs pathLinks={breadcrumbs} />
      <Row>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={growth} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ОТБИВАНЕ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={calf} alt="link-icon" /></figure>
            <p className="nav-card-title">
              КАСТРАЦИЯ
            </p>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default EventsCalfs;