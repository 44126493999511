import React from 'react';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';

const GroupAndLocationTab = props => {
  const options = [1, 2, 3, 4, 5];
  return (
    <div className="tab">
      <form>
        <Row>
          <Col lg="4">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| СТАДО |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Принадлежност към стадо!</p>
              </div>
            </div>
            <Row>
              <Col lg='6'>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Стадо</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg='6' className='d-flex'> <button type="button" className="btn btn-danger align-self-end mb-3">ДОБАВИ СТАДО</button></Col>
            </Row>
          </Col>
          <Col lg="7">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| ЛОКАЦИЯ |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Определи локация</p>
              </div>
            </div>
            <div className="d-flex" >
              <div className="form-group mr-3 w-100">
                <label htmlFor="exampleInputEmail1">Бокс / Падок</label>
                <DropDownList data={options} defaultValue="1" />
              </div>
              <div className="form-group mr-3 ml-3 w-100">
                <label htmlFor="exampleInputEmail1">Сектор</label>
                <DropDownList data={options} defaultValue="1" />
              </div>
              <div className="form-group ml-3 w-100">
                <label htmlFor="exampleInputEmail1">Обор</label>
                <DropDownList data={options} defaultValue="1" />
              </div>
            </div>
          </Col>
          <Col lg="4">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| ГРУПА |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Включи към мениджмънт група.</p>
              </div>
            </div>
            <Row>
              <Col lg='6'>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Стадо</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg='6' className='d-flex'> <button type="button" className="btn btn-danger align-self-end mb-3">СЪЗДАЙ ГРУПА</button></Col>
              <Col lg='12'> <p className="small text-success">Можете да присъединявате към повече от една група, задържайки - клавиш "Ctrl" или "Shift"</p></Col>
            </Row>
          </Col>
          <Col lg="6">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| СОБСТВЕНОСТ |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Информация за новия собственик.</p>
              </div>
            </div>
            <Row>
              <Col lg="8">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="info-text-blue">Собственик</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">% Собственост</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg="8">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="info-text-blue">Собственик 2</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">% Собственост</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg="8">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="info-text-blue">Собственик 3</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">% Собственост</label>
                  <DropDownList data={options} defaultValue="1" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <TabNavButtons parentProps={props} />
      </form>

    </div>
  )
};

export default GroupAndLocationTab;