import React from "react";

import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from "../../layout/breadcrumbs";
import total from "../../../assets/img/icons/ICON20.png";
import pregnancy from "../../../assets/img/icons/ICON21.png";
import calving from "../../../assets/img/icons/ICON22.png";
import productivity from "../../../assets/img/icons/ICON23.png";
import vet from "../../../assets/img/icons/ICON24.png";
import food from "../../../assets/img/icons/ICON25.png";
import deposit from "../../../assets/img/icons/ICON26.png";
import embrion from "../../../assets/img/icons/ICON27.png";
import generator from "../../../assets/img/icons/ICON28.png";
import bbh from "../../../assets/img/icons/ICON29.png";



const Reports = props =>{
    const breadcrumbs = [{ title: 'Отчети'}];

    return(
        <div className="container-fluid">
        <Breadcrumbs pathLinks={breadcrumbs} />

        <Row>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={total} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОБЩИ ОТЧЕТИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={pregnancy} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ ЗА БРЕМЕННОСТ </p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={calving} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ ЗА РАЖДАНИЯ И ОТЕЛВАНИЯ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={productivity} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ ЗА ПРОДУКТИВНОСТ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={vet} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ ЗА ВЕТЕРИНАРИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={food} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОБЩИ ЗА ХРАНЕНЕ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={deposit} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ ЗА ЗАПЛОЖДАНЕ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={embrion} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ ЗА ЕМБРИОТРАНСФЕР</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={generator} alt="link-icon" /></figure>
                    <p className="nav-card-title">ГЕНЕРАТОР НА ОТЧЕТИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={bbh} alt="link-icon" /></figure>
                    <p className="nav-card-title">ОТЧЕТИ БАБХ</p>
                </Link>
            </Col>
        </Row>
    </div>
    )
}

export default Reports;