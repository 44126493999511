import moment from 'moment';

const appDateFormat = 'DD.MM.YYYY';
const apiDateFormat = 'YYYY-MM-DD';


class DatesHelper {

  /**
   * Returns the current date with the default format for the application
   */
  static getCurrentDate() {
    return moment().format(appDateFormat);
  }

  /**
   * Returns a Moment object from the passed in date string
   * 
   * @param {string} dateString
   */
  static parseDate(dateString) {
    return moment(dateString);
  }

  /**
   * Formats the passed in dateString to the default application date format
   * 
   * @param {string} dateString
   */
  static parseAndFormatDate(dateString) {
    return moment(dateString).format(appDateFormat);
  }

  /**
  * Adds number of months to the dateString to the default application date format
  * 
  * @param {string} dateString
  * @param {number} months
  */

  static addMonthsToDate(dateString, months) {
    return moment(dateString).add(months, 'months').format(appDateFormat);
  }

  /**
   * Converts a date to the string format, required by the Rodeo API
   * 
   * @param {Date} date 
   * 
   * @returns {String}
   */
  static dateToApiFormat(date) {
    return moment(date).format(apiDateFormat);
  }
}

export default DatesHelper;