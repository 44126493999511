import React from 'react';
import { Route, Switch } from "react-router-dom";

import DataTab from './tabs/data-tab';
import ParentsTab from './tabs/parents-tab';
import PhysiologyTab from './tabs/physiology-tab';
import GroupAndLocationTab from './tabs/group-location';
import EconomyTab from './tabs/economy-tab';
import DocumentsTab from './tabs/documents-tab';
import EndTab from './tabs/end-tab';
import EntriesNavLinks from '../entries-nav-links';
import Breadcrumbs from '../../../../../layout/breadcrumbs';

import '../../../animal-card.css';



const IndividualEntries = props => {

    // Arrays used for demo purpose
    const tabs = {
        links: [
            '/animals/movement/individual-entries',
            '/animals/movement/individual-entries/parents',
            '/animals/movement/individual-entries/physiology',
            '/animals/movement/individual-entries/group-and-location',
            '/animals/movement/individual-entries/economy',
            '/animals/movement/individual-entries/documents',
            '/animals/movement/individual-entries/end'
        ],
        titles: [
            'Данни вписване',
            'Родители',
            'Физиология',
            'Групи и Локация',
            'Икономика',
            'Документи',
            'Край'
        ]
    };

    const breadcrumbs = [{ title: 'Животни', href: '/animals' }, { title: 'Движения', href:'/animals/movement' }, { title: 'Индивидуално вписване' }];

    return (
        <main>
            <div className='container-fluid'>
                <Breadcrumbs pathLinks={breadcrumbs} />
            </div>

            <div className="main-nav small-nav red-colored container-fluid">
                <EntriesNavLinks tabs={tabs}/>
            </div>

            <div className="container-fluid">
                <Switch>
                    <Route path={tabs.links[1]} render={props => {
                        return <ParentsTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[2]} render={props => {
                        return <PhysiologyTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[3]} render={props => {
                        return <GroupAndLocationTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[4]} render={props => {
                        return <EconomyTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[5]} render={props => {
                        return <DocumentsTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[6]} render={props => {
                        return <EndTab props={props} tabsLinks={tabs.links} />
                    }} />
                    <Route path={tabs.links[0]} render={props => {
                        return <DataTab props={props} tabsLinks={tabs.links} />
                    }} />
                </Switch>
            </div>
        </main>
    );
}

export default IndividualEntries;