import React from 'react';

import './dashboard-card.css';

const DashboardCard = props => {
  return (
    <div className={'dashboard-card ' + (props.isLight ? 'light' : '' ) +(props.className? props.className :'')}>
      {props.children}
    </div>
  )
};

export default DashboardCard;