import React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';


const ParentsTab = props => {
 
  return (
    <div className="tab">
      <form>
        <Row>
          <Col lg="4">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| МАЙКА |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Изберете майка на животното!</p>
              </div>
            </div>
            <Row>
              <Col lg='6'>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ВЕТИС № МАЙКА</label>
                  <Input />
                </div>
              </Col>
              <Col lg='6' className='d-flex'> <button type="button" className="btn btn-danger align-self-end mb-3">ДОБАВИ МАЙКА</button></Col>
            </Row>
          </Col>
          <Col lg="4">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| БАЩА |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Изберете баща на животното!</p>
              </div>
            </div>
            <Row>
              <Col lg='6'>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ВЕТИС № БАЩА</label>
                  <Input />
                </div>
              </Col>
              <Col lg='6' className='d-flex'> <button type="button" className="btn btn-danger align-self-end mb-3">ДОБАВИ МАЙКА</button></Col>
            </Row>
          </Col>
          <div className="w-100"></div>
          <Col lg="4">
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| СУРОГАТНА МАЙКА |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Изберете сурогатна майка на животното!</p>
              </div>
            </div>
            <Row>
              <Col lg='6'>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ВЕТИС № Сурогатна майка</label>
                  <Input />
                </div>
              </Col>
              <Col lg='6' className='d-flex'> <button type="button" className="btn btn-danger align-self-end mb-3">ДОБАВИ МАЙКА</button></Col>
            </Row>
          </Col>
        </Row>
        
        <TabNavButtons parentProps ={props}/>
      </form>

    </div>
  )
};

export default ParentsTab;