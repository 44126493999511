import moment from 'moment';

import DatesHelper from '../../../tools/DatesHelper';
import { DRY_WET_STATUSES,REGISTERS , COUNTRIES } from '../../../tools/translation-constants';

export const getAnimalAgeInMonths = animalBirthDate => moment().diff(moment(animalBirthDate), 'months');

export const getAnimalLastWeightObj = (animal) => {
  const weights = animal['weights'];
  const result = weights.length > 0 ? weights[weights.length - 1] : { weight: '-', dateWeight: '' };
  return result;
}

export const getAnimalWeightPerType = (animal, weightType) => animal.weights.filter(w => w.weightType === weightType)[0]

export const mapEventCell = animal => {
  // TODO The events cell content should be fetched in a different way
  const events = animal['events'];
  const eventTypes = [
    "Раждане",
    "Отелване",
    "Въвеждане в системата",
    "Постъпване в сървис",
    "Ваксинация",
    "Отчитане на бременност",
    "Отбиване",
    "Разгонване",
    "Пресушаване",
  ]
  let result = ''
  if (events && events.length > 0) {
    const lastDate = events.reduce((prev, current) => {
      return (new Date(prev.date) > new Date(current.date)) ? prev : current
    });
    result = eventTypes[lastDate.animalEventTypeID - 1];
  }
  return result;
}

export const generateBreedCompositionString = ({ breed, breedComposition }) => {
  if (!breed && breed !== null) {
    return '-';
  }

  if (!breedComposition || breedComposition.length === 0) {
    return breed.breedShort ? `100% (${breed.breedShort})` : '-';
  }

  return breedComposition.map(breedObj => `${100 * breedObj.breedPercent}% (${breedObj.breed.breedShort})`).join(', ');
};

export const generateLocationString = ({ animalLocation }) => {
  if (!animalLocation || animalLocation.length === 0) {
    return '-';
  }

  const i = animalLocation.length - 1;

  const currentLocation = animalLocation[i].location;
  const locationNames = [];
  if (currentLocation.locationName) {
    locationNames.push(currentLocation.locationName);
  }
  if (currentLocation.locationParent.locationName) {
    locationNames.push(currentLocation.locationParent.locationName);
  }
  if (currentLocation.locationParent.locationParent.locationName) {
    locationNames.push(currentLocation.locationParent.locationParent.locationName);
  }

  const locationString = locationNames.join(', ');
  const moveDate = animalLocation[i].moveDate ? DatesHelper.parseAndFormatDate(animalLocation[i].moveDate) : '';

  return moveDate ? `${locationString} (от ${moveDate})` : locationString;
};

export const generateSelectionString = ({ underSelection, holdTo }) => {
  const date = holdTo !== null ? ` (до ${DatesHelper.parseAndFormatDate(holdTo)})` : '';
  return underSelection ? `Да${date}` : 'Не';
}

export const generateDryWetStatusString = ({ dryWetStatus, cowBirthCount }) => {
  let dryWetStatusStr = DRY_WET_STATUSES.find(dws => dws.id === 'UNKNOWN').text;
  if (dryWetStatus === 'WET') {
    dryWetStatusStr = 'Да';
  }
  if (dryWetStatus === 'DRY') {
    dryWetStatusStr = 'Не';
  }
  return `${dryWetStatusStr} (${cowBirthCount})`
}

export const calculateAnimalMaleFemeleChildren = (animal) => {
  const result = {
    maleCount: 0,
    femaleCount: 0
  };
  if (animal.sex === 'MALE') {
    return false;
  }

  if (animal.children && animal.children.length > 0) {
    animal.children.forEach((child) => {
      if (child.sex === 'MALE') {
        result.maleCount++;
      }
      if (child.sex === 'FEMALE') {
        result.femaleCount++;
      }
    });
    return result;
  } else {
    return result;
  }
}

export const generatePEDString = (animal) => {
  const animalRegister = REGISTERS.find(register => register.id === animal.register).text;
  const animalCountry = COUNTRIES.find(country => country.id === animal.countryFrom).text;
  const animalHerdBook = animal.animalHerdBook ? animal.herdBookOut : '-';

  return `${animalRegister} | ${animalCountry} | ${animalHerdBook}`;
}