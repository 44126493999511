import React from 'react';

import Query from '../../../../data/query';
import { DATA_TAB_ENUMS } from '../../../../data/animal/tabs-queries.js';
import { GET_SIRES_AND_DAMS } from '../../../../data/animal/animal-query';
import VetisForm from './data/vetis-form';
import AnimalDataForm from './data/animal-data-form';


const DataTab = props => (
  <Query {...props} query={DATA_TAB_ENUMS}>
    {({ aClasses, breeds, color }) => {
      return (
        <Query query={GET_SIRES_AND_DAMS}>
          {({ sires, dams }) =>
            <div className="tab">
              <div className="tab-head d-flex justify-content-between">
                <div>
                  <div className="d-flex align-items-baseline">
                    <h3 className="mr-3">| ДАННИ НА ФЕРМАТА |</h3>
                  </div>
                  <p className="text-muted">Данни за животното , това са верните ви данни!</p>
                </div>
              </div>
              <AnimalDataForm currentAnimal={props.currentAnimal} aClasses={aClasses}
                breeds={breeds} color={color} sires={sires} dams={dams} />
              <div className="tab-head">
                <div>
                  <div className="d-flex align-items-baseline">
                    <h3 className="mr-3">| ВЕТИС ДАННИ |</h3>
                  </div>
                  <p className="text-muted">Данни от системата на БАБХ. Оцветените <span className="text-success">полета означават "несъответствие"</span></p>
                </div>
              </div>
              <VetisForm currentAnimal={props.currentAnimal} color={color} dams={dams} />
            </div>
          }
        </Query>
      )
    }}
  </Query>
);

export default DataTab;