import { gql } from "apollo-boost";

export const DATA_TAB_ENUMS = gql`
{
  aClasses {
    items {
      className
      classID
    }
  }
  breeds {
    items {
      breedName
      breedsId
    }
  }
  color {
    items {
      colorName
      colorID
    }
  }
}`;