import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import { DELETE_ANIMAL_VACCINE, GET_ANIMAL_VACCINE } from '../../../../../../data/animal/animal-query';

class DeleteVaccineModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            vaccineObj: props.vaccineObj
        };
        this.toggleDialog = this.toggleDialog.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });
    }


    handleSubmit(e, deleteAnimalVaccineTrigger) {
        e.preventDefault();
        deleteAnimalVaccineTrigger({
            variables:
            {
                animalVaccineDelete: this.state.vaccineObj.animalVaccineID
            }
        });
    }

    render() {
        return (
            <Mutation mutation={DELETE_ANIMAL_VACCINE} awaitRefetchQueries={false}
                refetchQueries={[{ query: GET_ANIMAL_VACCINE, variables: { id: this.state.vaccineObj.animalID } }]}
                onCompleted={() => this.toggleDialog()}
            >
                {(deleteAnimalVaccineTrigger) => (
                    <div className="d-inline-block">
                        <button className="button-icon mr-4" onClick={this.toggleDialog}><FontAwesomeIcon icon={'trash'} /></button>
                        {this.state.visible && <Dialog className='health-modal' title={"Изтрий събитие"} onClose={this.toggleDialog} minWidth='500px'>
                            <form className="health-form" onSubmit={e => this.handleSubmit(e, deleteAnimalVaccineTrigger)}>
                                <Row>
                                    <Col lg={{ size: 12 }}>
                                        <div className='mb-4 text-center'>
                                            Сигурни ли сте,че искате да изтриете събитието?
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-danger mr-3">Изтрий</button>
                                            <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Dialog>}
                    </div>
                )}
            </Mutation>
        );
    }
}

export default DeleteVaccineModal;