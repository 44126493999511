
import React from 'react';
import { Row, Col } from 'reactstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';

const EconomyTab = props => {
  const options = [1, 2, 3, 4, 5];

  return (
    <div className="tab">
      <form>
        <Row>
          <Col lg='12'>
            <div className="tab-head d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-baseline">
                  <h3 className="mr-3">| ЦЕНА |</h3>
                  <button className="button-icon icon-color-success">
                    <FontAwesomeIcon icon={'question-circle'} />
                  </button>
                </div>
                <p className="info-text-red" >Показатели с отношение към цената!</p>
              </div>
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Тегло</label>
              <Input />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Вид тегло</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Дата на теглене</label>
              <DatePicker defaultValue={new Date()} />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Цена за кг/жт</label>
              <Input />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Цена на глава</label>
              <Input />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Разходи за транспорт</label>
              <Input />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Ветеринарни разходи</label>
              <Input />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Други разходи</label>
              <Input />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Стойност на покупката</label>
              <Input />
            </div>
          </Col>
          <Col lg='2'>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Процент на разходите</label>
              <Input />
            </div>
          </Col>
        </Row>
        <TabNavButtons parentProps ={props}/>
      </form>

    </div>
  )
};

export default EconomyTab;