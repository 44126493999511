import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Query from "../../../data/query";
import { ACLASS_QUERY_FOR_DDL } from '../../../data/a-classes/a-class-query';
import {calculateAnimalMaleFemeleChildren} from './animal-utilities';

const AnimalPicBox = props => {

  // TODO: Reconsider the approach with retina images
  const imgUrls = [
    { img: require('../../../assets/img/3_MC.jpg'), retinaImg: '' },
    { img: require('../../../assets/img/4_FC.jpg'), retinaImg: '' },
    { img: require('../../../assets/img/5_BC.jpg'), retinaImg: '' },
    { img: require('../../../assets/img/6_H.jpg'), retinaImg: '' },
    { img: require('../../../assets/img/7_C.png'), retinaImg: require('../../../assets/img/7_C@2x.png') },
    { img: require('../../../assets/img/2_B.jpg'), retinaImg: '' },
    { img: require('../../../assets/img/1_BB.jpg'), retinaImg: '' },
  ];
  const resultAnimalCountChildren = calculateAnimalMaleFemeleChildren(props.animal);
  const printAnimalChildredCount =(resultAnimalCountChildren)=>{
      if (!resultAnimalCountChildren) {
        return ''
      }
      return(
        <span>
            <span className="text-accent">{resultAnimalCountChildren.maleCount}</span> | {resultAnimalCountChildren.femaleCount} |
        </span>
      );
  }
  return (
    <div className="pic-box">
      <div className="pic-box-border">
        <div className="content">
        <span>{props.animal.lID} | <span className="text-accent">{props.animal.vID}</span> | {props.animal.breed.breedShort} | <span className="text-accent">{props.animal.ageInMonths}</span> | {props.animal.lastWeight['weight']} | {printAnimalChildredCount(resultAnimalCountChildren)}</span>
        </div>
        <div className="actions">
          <button className="button-icon">
            <FontAwesomeIcon icon={'file-pdf'} color="#ffffff" />
          </button>
          <button className="button-icon">
            <FontAwesomeIcon icon={'print'} color="#ffffff" />
          </button>
          <button className="button-icon">
            <FontAwesomeIcon icon={'search'} color="#ffffff" />
          </button>
        </div>
      </div>
      <Query {...props} query={ACLASS_QUERY_FOR_DDL} variables={{ id: props.animal.animalId }}>
        {({ aClasses }) => {
          const resultClass = aClasses.items.find((el) => {
            return el.classID === props.animal.aClass.classID
          });
          return <picture>
            <source srcSet={imgUrls[resultClass.classID - 1].retinaImg} media="(min-resolution: 2dppx)" />
            <source srcSet={imgUrls[resultClass.classID - 1].retinaImg} media="(-webkit-min-device-pixel-ratio: 2)" />
            <source srcSet={imgUrls[resultClass.classID - 1].retinaImg} media="(min-device-pixel-ratio: 2)" />
            <source srcSet={imgUrls[resultClass.classID - 1].img} />
            <img src={imgUrls[resultClass.classID - 1].img} alt="animal-img" />
          </picture>
        }}
      </Query>
      <div className="pic-box-border">
        <div className="content">
          <span>{props.animal.name}</span>
        </div>
      </div>
    </div>
  );

};

export default AnimalPicBox;