import React from 'react';
import { Row, Col } from 'reactstrap';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Mutation from '../../../../../data/mutation';
import { EDIT_NOTE, GET_ANIMAL_NOTES } from '../../../../../data/animal/animal-query';
import DatesHelper from '../../../../../tools/DatesHelper';

class UpdateNoteModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal() {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState(this.initialState());
  }

  initialState() {
    return {
      visible: false,
      noteToUpdate: {
        animalID: this.props.animalId,
        noteID: this.props.currentNote.noteID,
        dateNote: DatesHelper.parseDate(this.props.currentNote.dateNote).toDate(),
        noteTopic: this.props.currentNote.noteTopic,
        note: this.props.currentNote.note
      }
    }
  }

  handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    const currentNoteToUpdate = { ...this.state.noteToUpdate };
    currentNoteToUpdate[name] = value;
    this.setState({ noteToUpdate: currentNoteToUpdate });
  }

  handleSubmit(e, updateNote) {
    e.preventDefault();
    updateNote({
      variables:
        { noteToUpdate: this.state.noteToUpdate }
    });
  }

  submitDisabled() {
    return this.state.noteToUpdate.dateNote === null || this.state.noteToUpdate.noteTopic.length === 0
      || this.state.noteToUpdate.note.length === 0;
  }

  noteEditedResolver(cache, updateNoteAnimal) {
    const currentIdString = this.props.animalId;
    const animalResult = cache.readQuery({ query: GET_ANIMAL_NOTES, variables: { id: currentIdString } });
    const targetIndex = animalResult.animal.notes
      .findIndex(n => n.noteID === updateNoteAnimal.noteID);

    if (targetIndex >= 0) {
      animalResult.animal.notes[targetIndex] = updateNoteAnimal
      cache.writeQuery({
        query: GET_ANIMAL_NOTES,
        variables: {
          id: currentIdString
        },
        data: { animal: animalResult.animal },
      });
    }

  }

  render() {
    return (
      <Mutation mutation={EDIT_NOTE}
        update={(cache, { data: { updateNoteAnimal } }) => this.noteEditedResolver(cache, updateNoteAnimal)}
        onCompleted={this.closeModal}
      >
        {(updateNoteTrigger) =>
          <div>
            <button className="button-icon" onClick={this.openModal}><FontAwesomeIcon icon={'pen'} /></button>
            {!this.state.visible ? '' :
              <Dialog title={"Редакция на бележка"} minWidth={"500px"} onClose={this.closeModal}>
                <form
                  onSubmit={e => this.handleSubmit(e, updateNoteTrigger)}
                >
                  <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                      <div className="form-group">
                        <label>Дата</label>
                        <DatePicker format="dd.MM.yyyy" value={this.state.noteToUpdate.dateNote} name="dateNote" onChange={e => this.handleChange(e)} />
                      </div>
                      <div className="form-group">
                        <label>Тема</label>
                        <Input type="text" name="noteTopic" onChange={e => this.handleChange(e)} value={this.state.noteToUpdate.noteTopic || ''} />
                      </div>
                      <div className="form-group">
                        <label>Бележка</label>
                        <textarea className="k-textarea" name="note" onChange={e => this.handleChange(e)} value={this.state.noteToUpdate.note} ></textarea>
                      </div>
                      <div className="d-flex">
                        <button type="submit" disabled={this.submitDisabled()} className="btn btn-success mr-3">Редактирай</button>
                        <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Отказ</button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Dialog>
            }
          </div>
        }
      </Mutation>
    );
  }
}

export default UpdateNoteModal;
