import { gql } from "apollo-boost";

const generateQuery = includeAnimals => `
{
    aClasses {
      items {
        classID
        className
        ${includeAnimals ? `animals (active: true) {
          active
        }` : ""}
      }
    }
  }
`

export default gql(generateQuery(true))
export const ACLASS_QUERY_FOR_DDL = gql(generateQuery(false))