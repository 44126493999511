import React from 'react';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from '../../layout/breadcrumbs';
import weights from '../../../assets/img/icons/ICON59.png';
import marking from '../../../assets/img/icons/ICON3.png';
import cows from '../../../assets/img/icons/ICON13.png';
import gate from '../../../assets/img/icons/ICON61.png';
import services from '../../../assets/img/icons/ICON8.png';
import clinicalMeasurements from '../../../assets/img/icons/ICON60.png';
import measurements from '../../../assets/img/icons/ICON20.png';

import './events.css';



const EventsShared = props => {
  const breadcrumbs = [{ title: 'Събития', href: '/events' }, { title: 'Общи събития' }];

  return (
    <div className="container-fluid">
      <Breadcrumbs pathLinks={breadcrumbs} />
      <Row>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={weights} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ИЗМЕРВАНЕ НА ТЕГЛО
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={marking} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ПРЕМАРКИРАНЕ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={marking} alt="link-icon" /></figure>
            <p className="nav-card-title">
              МАРКИРАНЕ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={cows} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ПРОМЯНА КЛАС НА ЖИВОТНО
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={gate} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ОЦЕНКА НА ТЕЛЕСНОТО СЪСТОЯНИЕ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={gate} alt="link-icon" /></figure>
            <p className="nav-card-title">
              S.E.U.R.O.P
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={services} alt="link-icon" /></figure>
            <p className="nav-card-title">
              КЛИНИЧНО ИЗСЛЕДВАНЕ БИК
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={clinicalMeasurements} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ИЗМЕРВАНЕ НА СКРОТУМА
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={measurements} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ИЗМЕРВАНИЯ
            </p>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default EventsShared;