import React, { Component} from "react";
import { Portal } from 'react-portal';

export default class Footer extends Component {
    render  = () => {
        return (
            <Portal>
                <footer id="footer" className="d-flex align-items-center justify-content-between">
                    <div className="form-group">
                        
                    </div>
                    <span className="d-block mr-3 text-white">&copy; { new Date().getFullYear() } - Network Agro Solutions</span>
                </footer>
            </Portal>
        )
    }
}