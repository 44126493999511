import React from 'react';
import { Row, Col } from 'reactstrap';

import medical from '../../../assets/img/icons/ICON7.png';
import medicalData from '../../../assets/img/icons/ICON24.png';
import medicalResults from '../../../assets/img/icons/ICON29.png';
import Breadcrumbs from '../../layout/breadcrumbs';

import './events.css';



const EventsVeterinary = props => {
  const breadcrumbs = [{ title: 'Събития', href: '/events' }, { title: 'Ветеринарни събития' }];
  return (
    <div className="container-fluid">
      <Breadcrumbs pathLinks={breadcrumbs} />
      <Row>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medical} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ПРИЛАГАНЕ НА ЛЕЧЕНИЕ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medical} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ДИАГНОСТИЦИРАНЕ НА ЗАБОЛЯВАНЕ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medicalData} alt="link-icon" /></figure>
            <p className="nav-card-title">
              КЛИНИЧНИ РЕЗУЛТАТИ БИКОВЕ ОБЩО
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medicalData} alt="link-icon" /></figure>
            <p className="nav-card-title">
              РЕЗУЛТАТИ ОТ ПРОБИ НА БИКОВЕ ЗА ВЕНЕРИЧЕСКИ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medicalResults} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ОТЧИТАНЕ НА РЕЗУЛТАТИ КОМБО
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medicalResults} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ВЗЕМАНЕ НА КРЪВ БРУЦЕЛОЗА
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medicalResults} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ТУБЕРКУЛИНИЗАЦИЯ
            </p>
          </a>
        </Col>
        <Col lg="2" className="mb-5">
          <a href="/" className="nav-card">
            <figure><img src={medicalResults} alt="link-icon" /></figure>
            <p className="nav-card-title">
              ТУБЕРКУЛИНИЗАЦИЯ И БРУЦЕЛОЗА КОМБО
            </p>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default EventsVeterinary;