import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Mutation from '../../../../../../data/mutation';
import { ADD_ANIMAL_BCS, GET_ANIMAL_BCS } from '../../../../../../data/animal/animal-query';
import FarmClientDropdown from '../../health/farm-client-dropdown';
import { ANIMAL_OTS_TYPES } from '../../../../../../tools/translation-constants';

class AddBcsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            bCSObj: props,
            submitDisabled: true,
            tableType:'bCS'
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });

        if (!this.state.visible) {
            this.resetState();
        }
    }


    handleFormChange(e) {
        const objCopy = { ...this.state.bCSObj };

        if ('animalOTS' === e.target.name) {
            objCopy[e.target.name] = e.target.value.id;
        }else if('farmClientID' === e.target.name){
            objCopy[e.target.name] = e.target.value.farmClientID;
        } else {
            objCopy[e.target.name] = e.target.value;
        }
        const isFormSubmitable = Object.keys(objCopy).length > 1 ? false:true;
        this.setState({
            bCSObj: objCopy,
            submitDisabled:isFormSubmitable
        });
       
    }

    handleSubmit(e, addAnimalBCSTrigger) {
        e.preventDefault();
        const stateCopy = { ...this.state.bCSObj };
        let dataToSave;

        if (!stateCopy.hasOwnProperty('bCSDate')) {
            stateCopy['bCSDate'] = new Date();
            dataToSave ={...stateCopy};
        }else{
            dataToSave ={...this.state.bCSObj};
        }

        if (Object.keys(dataToSave).length > 1) {
            addAnimalBCSTrigger({
                variables:
                {
                    animalAddBCS: dataToSave
                }
            });
        }
    }

    resetState() {
        const animalID = this.state.bCSObj.animalID;
        this.setState({
            bCSObj: {
                animalID: animalID
            },
            submitDisabled: true
        })
    }

    render() {
        return (
            <Mutation mutation={ADD_ANIMAL_BCS} awaitRefetchQueries={true}
                refetchQueries={[{ query: GET_ANIMAL_BCS, variables: { id: this.state.bCSObj.animalID } }]}
                onCompleted={() => this.toggleDialog()}
            >
                {(addAnimalBCSTrigger) => (
                    <div>
                        <button className="btn btn-danger" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
                        {this.state.visible && <Dialog className="health-modal" title={"Добави събитие"} onClose={this.toggleDialog} minWidth='500px' height="70%">
                            <form className="health-form" onSubmit={e => this.handleSubmit(e, addAnimalBCSTrigger)}>
                                <Row>
                                    <Col lg={{ size: 8, offset: 2 }}>
                                        <div className="form-group">
                                            <label>ОТС</label>
                                            <DropDownList
                                                style={{ width: '100%' }}
                                                data={ANIMAL_OTS_TYPES}
                                                dataItemKey="id"
                                                textField="text"
                                                name="animalOTS"
                                                required={true}
                                                onChange={this.handleFormChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Отговорник</label>
                                            <FarmClientDropdown tableType={this.state.tableType} handleFormChange={this.handleFormChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Дата</label>
                                            <DatePicker format="dd.MM.yyyy" value={this.state.bCSObj.bCSDate ? this.state.bCSObj.bCSDate : new Date()} name="bCSDate" onChange={this.handleFormChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Бележка</label>
                                            <textarea className="k-textarea" name="comment" onChange={this.handleFormChange} ></textarea>
                                        </div>
                                        <div className="d-flex">
                                            <button type="submit" disabled={this.state.submitDisabled} className="btn btn-success mr-3">Добави</button>
                                            <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Dialog>}
                    </div>
                )}
            </Mutation>
        );
    }
}

export default AddBcsModal;