import React from 'react';
import { Row, Col } from 'reactstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';


const DataTab = props => {
  const options = [1, 2, 3, 4, 5];

  return (
    <div className="tab">
      <form>
        <div className="tab-head d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-baseline">
              <h3 className="mr-3">| ДАННИ ЗА ЗАПРИХОЖДАВАНЕТО |</h3>
              <button className="button-icon icon-color-success">
                <FontAwesomeIcon icon={'question-circle'} />
              </button>
            </div>
            <p className="info-text-red" >Изберете данни за заприхождаване на едно животно!</p>
          </div>
        </div>
        <Row>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Категория</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Пол</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Вид заприхождаване</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Дата на раждане</label>
              <DatePicker defaultValue={new Date()} />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Дата на заприхождаване</label>
              <DatePicker defaultValue={new Date()} />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Собственост фирма</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
        </Row>

        <div className="tab-head d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-baseline">
              <h3 className="mr-3">| ДАННИ ЗА ИДЕНТИФИКАЦИЯ |</h3>
              <button className="button-icon icon-color-success">
                <FontAwesomeIcon icon={'question-circle'} />
              </button>
            </div>
            <p className="info-text-red" >Данни за идентификация!</p>
          </div>
        </div>
        <Row>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">ВЕТИС №</label>
              <Input />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">ВИН №</label>
              <Input />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">EID №</label>
              <Input />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Вид EID</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Име</label>
              <Input />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Паспорт №</label>
              <Input />
            </div>
          </Col>
        </Row>

        <div className="tab-head d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-baseline">
              <h3 className="mr-3">| ДАННИ ЗА ПОРОДАТА |</h3>
              <button className="button-icon icon-color-success">
                <FontAwesomeIcon icon={'question-circle'} />
              </button>
            </div>
            <p className="info-text-red" >Породни данни, композит, регистър, цвят!</p>
          </div>
        </div>
        <Row>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Порода</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Породен композит</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Развъден регистър ІBGІ</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Раздел в регистъра ІBGІ</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Племенен регистър 2*</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Произход държава</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Развъдчик - Ферма</label>
              <Input />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Цвят</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Рога</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Направление</label>
              <DropDownList data={options} defaultValue="1" />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">ДНК №</label>
              <Input />
            </div>
          </Col>
          <Col lg="2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">ДНК ТИП</label>
              <Input />
            </div>
          </Col>
        </Row>

        <TabNavButtons parentProps={props} />
      </form>

    </div>
  )
};

export default DataTab;