import React from 'react';
import { NavLink } from "react-router-dom";

import '../../animal-card.css';


const TabNavButtons = props => {
  const links = props.parentProps.tabsLinks;
  const index = links.findIndex((p) => p === props.parentProps.props.location.pathname);
  const next = index !== links.length - 1 ? links[index + 1] : links[links.length - 1];
  const prev = index !== 0 ? links[index - 1] : links[0];

  return (
    <div className="tab-nav-buttons action-buttons d-flex justify-content-end mt-5">
      <button type="button" className="btn btn-danger">ОТКАЖИ</button>
      <NavLink exact className="btn btn-info" to={prev}>НАЗАД</NavLink>
      <button type="button" className="btn btn-success">ЗАПИШИ</button>
      <NavLink exact className="btn btn-info" to={next}>НАПРЕД</NavLink>
    </div>
  )
}


export default TabNavButtons;