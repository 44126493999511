import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Query from "../../../../../data/query";
import { GET_FARMCIENTS } from '../../../../../data/animal/animal-query';


const FarmClientDropdown = props => {

    const HEALTH_TABLES_FARM_CLIENT_TYPES = {
        'bCS': ['vet', 'farm'],
        'vaccine': ['vet']
    }

    const FARM_CLIENT_TYPE = HEALTH_TABLES_FARM_CLIENT_TYPES[props.tableType];

    const compareFarmTypes = (curFarmClient, curType, FARM_CLIENT_TYPE) => {
        for (let i = 0; i < FARM_CLIENT_TYPE.length; i++) {
            if (curType === FARM_CLIENT_TYPE[i].toLocaleLowerCase()) {
                return curFarmClient;
            }
        }
    }

    const filterFarmClients = (farmClients) => {
        const result = farmClients.items.filter((el) => {
            const curFarmClient = el;
            return el.farmClientsInType.find((type) => {
                const curType = type.toLowerCase();
                return compareFarmTypes(curFarmClient, curType, FARM_CLIENT_TYPE);
            });
        }).map((el) => {
            return {
                farmClientID: el.farmClientID,
                farmClientName: el.farmClientName
            }
        });
        return result
    }
    return (
        <Query query={GET_FARMCIENTS}>
            {({ farmClients }) => {
                const farmClList = filterFarmClients(farmClients);
                let selected;
                if (props.farmClientID) {
                    selected = farmClList.find((el) => {
                        return el.farmClientID === props.farmClientID
                    });
                }
                return (
                    <DropDownList
                        style={{ width: '100%' }}
                        data={farmClList}
                        dataItemKey="farmClientID"
                        textField="farmClientName"
                        name="farmClientID"
                        value={selected}
                        onChange={props.handleFormChange} />
                );
            }}
        </Query>
    );
}

export default FarmClientDropdown;