import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import { UPDATE_ANIMAL_BCS, GET_ANIMAL_BCS } from '../../../../../../data/animal/animal-query';
import FarmClientDropdown from '../../health/farm-client-dropdown';
import { ANIMAL_OTS_TYPES } from '../../../../../../tools/translation-constants';


class EditBcsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            bCSObj: props.bCSObj,
            changes: {
                animalID: props.bCSObj.animalID,
                bCSID: props.bCSObj.bCSID,
                bCSDate: props.bCSObj.bCSDate
            },
            initialState: props.bCSObj,
            submitDisabled: true,
            tableType:'bCS'
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });

        if (!this.state.visible) {
            this.resetChanges();
        }
    }


    handleFormChange(e) {
        let objCopy = { ...this.state.bCSObj };
        let changes = { ...this.state.changes };

        if ('animalOTS' === e.target.name) {
            objCopy[e.target.name] = e.target.value.id;
            changes[e.target.name] = e.target.value.id;
        }else if('farmClientID' === e.target.name){
            objCopy[e.target.name] = e.target.value.farmClientID;
            changes[e.target.name] = e.target.value.farmClientID;
        } else {
            objCopy[e.target.name] = e.target.value;
            changes[e.target.name] = e.target.value;
        }

        const isFormSubmitable = Object.keys(changes).length > 1 ? false : true;
        this.setState({
            bCSObj: objCopy,
            changes: changes,
            submitDisabled: isFormSubmitable
        });
    }

    handleSubmit(e, updateAnimalBCSTrigger) {
        e.preventDefault();

        updateAnimalBCSTrigger({
            variables:
            {
                animalUpdateBCS: this.state.changes
            }
        });
        this.setState({
            initialState:this.state.bCSObj
        });
        this.resetChanges();
    }

    resetChanges() {
        this.setState({
            changes: {
                animalID: this.state.bCSObj.animalID,
                bCSID: this.state.bCSObj.bCSID,
                bCSDate: this.state.bCSObj.bCSDate
            },
            submitDisabled:true,
            bCSObj: this.state.initialState
        });
    }

    setFarmClientIdDropdownValue(){
        let selectedFarmClientId;
        if (this.state.bCSObj.farmClientID) {
            selectedFarmClientId = this.state.bCSObj.farmClientID
        }else if (this.state.bCSObj.personInCharge) {
            selectedFarmClientId = this.state.bCSObj.personInCharge.farmClientID
        }else{
            selectedFarmClientId ='';
        }
        return selectedFarmClientId;
    }

    render() {
        const  selectedOTC = ANIMAL_OTS_TYPES.find((el) => {
            return el.id === this.state.bCSObj.animalOTS
        });

        return (
            <Mutation mutation={UPDATE_ANIMAL_BCS} awaitRefetchQueries={true}
                refetchQueries={[{ query: GET_ANIMAL_BCS, variables: { id: this.state.bCSObj.animalID } }]}
                onCompleted={() => this.toggleDialog()}
            >
                {(updateAnimalBCSTrigger) => (
                    <div className="d-inline-block">
                        <button className="button-icon" onClick={this.toggleDialog}><FontAwesomeIcon icon={'pen'} /></button>
                        {this.state.visible && <Dialog className='health-modal' title={"Редактирай събитие"} onClose={this.toggleDialog} minWidth='500px' height="70%">
                            <form className="health-form" onSubmit={e => this.handleSubmit(e, updateAnimalBCSTrigger)}>
                                <Row>
                                    <Col lg={{ size: 8, offset: 2 }}>
                                        <div className="form-group">
                                            <label>ОТС</label>
                                            <DropDownList
                                                style={{ width: '100%' }}
                                                data={ANIMAL_OTS_TYPES}
                                                dataItemKey="id"
                                                textField="text"
                                                name="animalOTS"
                                                required={true}
                                                value={selectedOTC}
                                                onChange={this.handleFormChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Отговорник</label>
                                            <FarmClientDropdown tableType={this.state.tableType} farmClientID={this.setFarmClientIdDropdownValue()} handleFormChange={this.handleFormChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Дата</label>
                                            <DatePicker format="dd.MM.yyyy" value={new Date(this.state.bCSObj.bCSDate)} onChange={this.handleFormChange} name="bCSDate" />
                                        </div>
                                        <div className='form-group'>
                                            <label>Бележка</label>
                                            <textarea className="k-textarea" name="comment" value={this.state.bCSObj.comment ? this.state.bCSObj.comment : ""} onChange={this.handleFormChange}></textarea>
                                        </div>
                                        <div className="d-flex">
                                            <button type="submit" disabled={this.state.submitDisabled} className="btn btn-success mr-3">Добави</button>
                                            <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Dialog>}
                    </div>
                )}
            </Mutation>
        );
    }
}

export default EditBcsModal;