import { gql } from "apollo-boost";
import { LOCATION_TYPES } from "../../tools/translation-constants";

const generateQuery = type => `
{
    locations(locationType: ${type}) {
        items {
            name: locationName
            value : locationID
        }
    }
}
`

export const BOX_QUERY = gql(generateQuery(LOCATION_TYPES.BOX.text))
export const BARN_QUERY = gql(generateQuery(LOCATION_TYPES.BARN.text))
export const PADDOCK_QUERY = gql(generateQuery(LOCATION_TYPES.PADDOCK.text))
export const SECTOR_QUERY = gql(generateQuery(LOCATION_TYPES.SECTOR.text))
export const PASTURE_QUERY = gql(generateQuery(LOCATION_TYPES.PASTURE.text))