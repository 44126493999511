import {  combineReducers } from 'redux';
import { auth } from "./auth-reducer";
import { animal } from "./animal/animal-reducer";
import { localizeReducer } from 'react-localize-redux';


export default combineReducers({ 
    localize: localizeReducer,
    auth,
    animal
  })