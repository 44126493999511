import React from "react";
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Breadcrumbs from "../../layout/breadcrumbs";

import ICON11 from "../../../assets/img/icons/ICON11.png";
import ICON12 from "../../../assets/img/icons/ICON12.png";
import ICON13 from "../../../assets/img/icons/ICON13.png";
import ICON14 from "../../../assets/img/icons/ICON14.png";
import ICON15 from "../../../assets/img/icons/ICON15.png";
import ICON16 from "../../../assets/img/icons/ICON16.png";


const AnimalsMain = _ => {
    const breadcrumbs = [{ title: 'Животни'}];
    return (
        <div className="container-fluid">

        <Breadcrumbs pathLinks={breadcrumbs}/>

        <Row>
            <Col lg="2" className="mb-5">
                <Link to="/animals/list" className="nav-card">
                    <figure><img src={ICON11} alt="link-icon" /></figure>
                    <p className="nav-card-title">ИЗБОР НА ЖИВОТНИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="/animals/movement" className="nav-card">
                    <figure><img src={ICON12} alt="link-icon" /></figure>
                    <p className="nav-card-title">ДВИЖЕНИЯ </p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON13} alt="link-icon" /></figure>
                    <p className="nav-card-title">ГРУПИ ЖИВОТНИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON14} alt="link-icon" /></figure>
                    <p className="nav-card-title">СЕМЕНЕН МАТЕРИАЛ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON15} alt="link-icon" /></figure>
                    <p className="nav-card-title">ЕМБРИОНИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON16} alt="link-icon" /></figure>
                    <p className="nav-card-title">ГЕНЕТИЧНИ ОЦЕНКИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON13} alt="link-icon" /></figure>
                    <p className="nav-card-title">ГЕНЕТИЧНИ ОЦЕНКИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON13} alt="link-icon" /></figure>
                    <p className="nav-card-title">ПРЕМАХВАНЕ НА ЖИВОТНИ</p>
                </Link>
            </Col>
            <Col lg="2" className="mb-5">
                <Link to="#" className="nav-card">
                    <figure><img src={ICON13} alt="link-icon" /></figure>
                    <p className="nav-card-title">КОНТРОЛ ЖИВОТНИ</p>
                </Link>
            </Col>
        </Row>
    </div>
    )
} 

export default AnimalsMain;