import { gql } from "apollo-boost";

export default gql`
{
        farmEvents {
            totalCount
            items {
            userID
            farmEventID
            farmEventTypeID
            farmID
            farmEventType {
                farmEventTypeName
            }
            dateCreate
        }
    }
}
`;