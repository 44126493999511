import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Home from "./components/views/home/home"
import Animals from "./components/views/animals/animals";
import Auth from "./components/auth/auth";
import Callback from "./components/auth/callback";
import Index from "./components/layout/index";
import Defaults from "./data/local/defaults";
import Events from "./components/views/events/events";
import Reports from "./components/views/reports/reports";

import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css";
import './fontawesome.js';
import './assets/css/twitter-bootstrap/bootstrap.css';
import { GRAPHQL_URL } from "./config";
import { createStore } from 'redux';
import { Provider } from "react-redux";
import { LocalizeProvider } from 'react-localize-redux';
import reducers from "./data/redux/reducers/main-reducer";


const auth = new Auth();

const httpLink = createHttpLink({
  uri: GRAPHQL_URL
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("access_token");
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token || ""}`,
    }
  }
});

const store = createStore(reducers);

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache: cache,
  link: authLink.concat(httpLink),
});
cache.writeData({
  data: Defaults,
});

const handleAuthentication = async ({ location, history }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication(history);
  }
}

const IndexContainer = () => {
  return (
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <ApolloProvider client={client}>
          <BrowserRouter>
              <Index auth={auth}>
                <Switch>
                  <Route path="/" exact render={props => <Home auth={auth} {...props} />} />
                  <Route path="/animals" render={props => <Animals {...props} auth={auth} />} />
                  <Route path="/events" component={Events} />
                  <Route path="/reports" component={Reports} />
                  <Route path="/callback" render={props => {
                    handleAuthentication(props);
                    return <Callback auth={auth} {...props} />
                  }} />
                </Switch>
              </Index>
            </BrowserRouter>
          
        </ApolloProvider>
      </LocalizeProvider>
    </Provider>
  )
};

const rootElement = document.getElementById("root");
ReactDOM.render(<IndexContainer />, rootElement);
