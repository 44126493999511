import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Query from '../../../../data/query';
import Pedigree from '../../../lib/pedigree/pedigree';
import BreedingForm from './breeding/breeding-form';
import { GET_ANIMAL_BREEDING_DATA } from '../../../../data/animal/animal-query';

const BreedingTab = props => {
  return (
    <div className="tab">
      <div className="tab-head">
        <div className="d-flex align-items-baseline mb-4">
          <h3 className="mb-0">| РАЗВЪДНИ |</h3>
          <div className="d-flex align-items-baseline ml-3">
            <button className="button-icon icon-color-success">
              <FontAwesomeIcon icon={'question-circle'} />
            </button>
            <div className="ml-3">
              <strong className="d-block">А | PED | НАМГБ | ДНК 134/567 | SNP |</strong>
              <strong className="d-block">7/7/7 | @0 | 29 кг | @200 | 185 кг | @ 365 | 388 кг |</strong>
            </div>
          </div>
        </div>
        <div className="tab-head-actions">
          <button className="button-icon icon-large mr-3">
            <FontAwesomeIcon icon={'file-pdf'} className="text-accent" />
          </button>
          <button className="button-icon icon-large">
            <FontAwesomeIcon icon={'print'} className="text-accent" />
          </button>
          <p className="text-muted">| Развъдни данни | Родословие |</p>
        </div>
      </div>
      <div>
        <Query query={GET_ANIMAL_BREEDING_DATA} variables={{ id: props.currentAnimal.animalId }}>
          {(data) => {
            return <BreedingForm animalId={props.currentAnimal.animalId} breedingData={data} />
          }}
        </Query>
        <Pedigree currentAnimalId={props.currentAnimal.animalId} />
      </div>
    </div>
  )
};

export default BreedingTab;