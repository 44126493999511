import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Mutation from '../../../../../data/mutation';
import { DELETE_WEIGHT, GET_ANIMAL_DETAILS, GET_ANIMAL_ADG } from '../../../../../data/animal/animal-query';

class DeleteWeightModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible
    });
  }

  handleSubmit(e, deleteWeight) {
    e.preventDefault();
    this.toggleDialog();
    deleteWeight({
      variables:
      {
        targetWeight: {
          weightID: this.props.weightID,
          animalID: this.props.animalID
        }
      }
    });
  }

  render() {
    return (
      <Mutation mutation={DELETE_WEIGHT}
        refetchQueries={[
          { query: GET_ANIMAL_ADG, variables: { id: this.props.animalID } },
          { query: GET_ANIMAL_DETAILS, variables: { id: this.props.animalID } }
        ]}
        awaitRefetchQueries={true}
      >
        {(deleteWeightTrigger) =>
          <div className="d-inline-block">
            <button type="button" className="button-icon mr-4" onClick={this.toggleDialog}><FontAwesomeIcon icon={'trash'} /></button>
            {this.state.visible && <Dialog className='health-modal' title={"Изтрий тегло"} onClose={this.toggleDialog} minWidth='500px'>
              <form className="health-form" onSubmit={e => this.handleSubmit(e, deleteWeightTrigger)}>
                <Row>
                  <Col lg={{ size: 12 }}>
                    <div className='mb-4 text-center'>
                      Сигурни ли сте,че искате да изтриете този запис?
                                </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn btn-danger mr-3">Изтрий</button>
                      <button type="button" className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Dialog>}
          </div>
        }
      </Mutation>
    );
  }
}

export default DeleteWeightModal;