
import React from 'react';
import TabNavButtons from '../../tab-nav-buttons';

const EndTab = props => {
 
  return (
    <div className="tab">
      <form>
          <h1>END tab</h1>
        <TabNavButtons parentProps ={props}/>
      </form>
     
    </div>
  )
};

export default EndTab;