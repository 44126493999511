import React from 'react';


import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';


const TableTab = props => {

    return (
        <div className="tab">
            <form>
                <h3>TableTap</h3>

                <TabNavButtons parentProps={props} />
            </form>
        </div>
    )
};

export default TableTab;