import React, { Component} from "react";

const style = {
    border: 0,
    backgroundColor: "#8dc73f",
    width: 48,
    height: 48,
    fontSize: "26px",
    color: "#fff",
    boxShadow: "0 0 5px rgba(255,255,255,.3)",
    textAlign: "center",
    fontWeight: 600,
    textTransform: "uppercase",
    cursor: "pointer",
    borderRadius: "25px"
}


export default class SideMenu extends Component { 
    render  = () => {  
        const user = JSON.parse(localStorage.getItem("user")) || { };
        return (
            <aside className={this.props.isOpen ? '': 'to-left'}>
                <div style={{textAlign: "right", paddingRight: 20}}>
                    <button className="btn-profile" style={style} type="button" id="profile-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg style={{fill: "#fff"}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 470 470"  xmlSpace="preserve">
                    <g>
                    <path d="M403.204,336.001c-0.46-0.485-0.984-0.907-1.558-1.256c-12.934-10.356-27.465-18.792-43.163-24.872
                            c-0.406-0.199-0.832-0.363-1.276-0.486c-16.135-6.094-33.475-9.705-51.556-10.369c-1.362-1.89-3.576-3.125-6.083-3.125
                            s-4.722,1.235-6.083,3.125c-18.082,0.665-35.422,4.275-51.556,10.369c-0.445,0.124-0.872,0.288-1.279,0.487
                            c-15.697,6.081-30.228,14.516-43.161,24.871c-0.575,0.349-1.099,0.771-1.56,1.257c-36.586,30.029-59.967,75.583-59.967,126.498
                            c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5c0-40.628,16.395-77.495,42.908-104.348v102.727c0,0.557,0.065,1.099,0.18,1.621
                            c0.741,3.362,3.734,5.879,7.32,5.879s6.578-2.517,7.32-5.879c0.115-0.522,0.18-1.064,0.18-1.621V344.867
                            c8.509-6.575,17.746-12.249,27.57-16.878v63.088c0,4.143,3.357,7.5,7.5,7.5h111.255c4.143,0,7.5-3.357,7.5-7.5v-63.088
                            c9.824,4.629,19.062,10.302,27.57,16.878v116.012c0,0.557,0.065,1.099,0.18,1.621c0.741,3.362,3.734,5.879,7.32,5.879
                            s6.578-2.517,7.32-5.879c0.115-0.522,0.18-1.064,0.18-1.621V358.151c26.514,26.853,42.908,63.72,42.908,104.349
                            c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5C463.172,411.585,439.79,366.031,403.204,336.001z M251.438,383.577v-61.68
                            c12.845-4.409,26.478-7.108,40.628-7.815v19.313c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-19.313
                            c14.149,0.707,27.782,3.406,40.627,7.814v61.681H251.438z" />

                    <path d="M157.678,143.304V7.5c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v128.304h-30.283V7.5c0-4.143-3.357-7.5-7.5-7.5
                            s-7.5,3.357-7.5,7.5v128.304H67.111V7.5c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v128.304H21.828V7.5
                            c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v135.804c0,21.384,17.396,38.78,38.78,38.78h29.145V462.5c0,4.143,3.357,7.5,7.5,7.5
                            s7.5-3.357,7.5-7.5V182.084h29.145C140.281,182.084,157.678,164.688,157.678,143.304z M23.047,150.804h118.412
                            c-3.149,9.447-12.07,16.28-22.561,16.28H45.608C35.117,167.084,26.196,160.251,23.047,150.804z" />

                    <path d="M180.893,182.084h33.527c-0.265,2.752-0.407,5.521-0.407,8.291c0,47.175,38.379,85.554,85.554,85.554
                            c47.174,0,85.553-38.379,85.553-85.554c0-2.77-0.143-5.539-0.408-8.291h33.248c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5
                            h-36.028l-17.409-72.345c-1.72-7.146-6.124-13.07-12.4-16.68c-6.278-3.609-13.61-4.437-20.655-2.328l-31.107,9.313l-31.106-9.313
                            c-7.021-2.104-14.37-1.294-20.691,2.278c-6.322,3.571-10.808,9.446-12.631,16.545l-18.632,72.529h-36.405
                            c-4.143,0-7.5,3.357-7.5,7.5S176.75,182.084,180.893,182.084z M255.938,91.069c2.734-1.545,5.934-1.89,9.011-0.969l33.258,9.958
                            c1.404,0.42,2.898,0.42,4.303,0l33.259-9.958c3.055-0.914,6.207-0.572,8.874,0.962c2.669,1.535,4.549,4.087,5.295,7.187
                            l16.565,68.835H232.784l17.673-68.797C251.256,95.178,253.202,92.615,255.938,91.069z M370.119,190.375
                            c0,38.903-31.649,70.554-70.553,70.554s-70.554-31.65-70.554-70.554c0-2.776,0.181-5.545,0.502-8.291h140.103
                            C369.938,184.831,370.119,187.601,370.119,190.375z" />

                    <path d="M355.19,413.58h-61.25c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h61.25c4.143,0,7.5-3.357,7.5-7.5
                            S359.333,413.58,355.19,413.58z" />

                    <path d="M263.94,413.58h-20c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h20c4.143,0,7.5-3.357,7.5-7.5
                            S268.083,413.58,263.94,413.58z" />

                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    </svg>
                    </button>
                    <div className="side-menu-user">{user.nickname}</div>
                </div>
                <ul className="nav flex-column aside-nav text-right">
                    <li>
                        <span className="nav-link active drop-link">Табло</span>
                        <ul className="nav flex-column drop-menu">
                            <li><a href="/" className="nav-link">Табло</a></li>
                            <li><a href="/" className="nav-link">Табло</a></li>
                            <li><a href="/" className="nav-link">Табло</a></li>
                            <li><a href="/" className="nav-link">Табло</a></li>
                        </ul>

                    </li>
                    <li>
                        <a className="nav-link" href="/">Обори</a>
                    </li>
                    <li>
                        <a className="nav-link" href="/">Пасища</a>
                    </li>
                    <li>
                        <a className="nav-link" href="/">Стада</a>
                    </li>
                    <li>
                        <a className="nav-link" href="/">Движения</a>
                    </li>
                    <li>
                        <a className="nav-link" href="/">Рапорти</a>
                    </li>
                    <li>
                        <a className="nav-link" href="/">Настройки</a>
                    </li>
                    <li>
                        <a className="nav-link" href="/">Помощ</a>
                    </li>
                    <li>
                        <button className="nav-link d-inline-block side-menu-logout" onClick={this.props.auth.logout}>Изход</button>
                    </li>
                </ul>
            </aside>
        )
    }
}