import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { compose } from "ramda";
import { SEXES } from "../../../tools/translation-constants";
import { withLocalize } from 'react-localize-redux';
import Query from "../../../data/query";
import { ACLASS_QUERY_FOR_DDL } from "../../../data/a-classes/a-class-query";
import { COLORS_QUERY_FOR_DDL } from "../../../data/colors/colors-query";
import { BREED_QUERY_FOR_DDL } from "../../../data/breeds/breeds-query";
import { BOX_QUERY, BARN_QUERY } from "../../../data/locations/locations-query";
import { 
  COUNTRY_FROM, 
  ANIMAL_REGISTER, 
  ANIMAL_OTS, 
  ANIMAL_INPUT, 
  ANIMAL_BREEDING_STATUS,
  ANIMAL_SEX
} from "../../../data/enums/enums-query";
import Enum from "../../utils/enum";


import { 
  animalBreedingStatusFilter,
  animalInputTypeFilter,
  animalOTSFilter,
  animlaRegisterFilter,
  countryFrom,
  barns,
  filterByBirthDate, 
  filterByWeight, 
  filterByAgeInMonths,
  filterAClass,
  filterBreed,
  filterSex,
  filterColor,
  filterGeneral,
  filterCowBirthCount,
  filterBoxes
} from "./animal-list-filter-functions";


const DetailsCell = _ => <td><NavLink to={`/animals/${_.dataItem.id}`}>{_.dataItem.lID}</NavLink></td>;
const ANIMALS_LISTING_PAGE_SIZE = 15;

class AnimalsList extends React.Component {

  _export;
  export = () => {
    this._export.save();
  }

  constructor(props) {
    super(props);
    
    this.addTranslationsForActiveLanguage();
    
    this.state = this.createAppState({
      skip: 0,
      //take: ANIMALS_LISTING_PAGE_SIZE,
      globalFilter: { }
    });
  }


  createAppState(dataState, items) {
    const excelDataState = {
      filter: dataState.filter,
      sort: dataState.sort,
      group: dataState.group
    };


    return {
      result: process(items || this.props.animals.items, dataState),
      dataState: dataState,
      excelItems: process(items || this.props.animals.items, excelDataState)
      
    };
  }

  changeFilterValue = (e, prop)  => {
    
    const value = e.target.value;

    const filter = this.state.dataState.globalFilter || { };
    
    filter[prop] = value.isDefault ? null : value;

    this.state.dataState.globalFilter = filter;

    this.setState({ dataState: { ...this.state.dataState } });
    
    console.log(this.state.dataState.globalFilter);
  }

  dataStateChange = e => this.setState(this.createAppState(e.data, this.state.result.data))

  expandChange = event => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.setState({
      result: { ...this.state.result },
      dataState: this.state.dataState
    });
  }

  

  applyGlobalFilter = _ => {
    const { dataState: { globalFilter: filter } } = this.state;

    const res = compose(
        animalBreedingStatusFilter,
        animalInputTypeFilter,
        animalOTSFilter,
        animlaRegisterFilter,
        countryFrom,
        barns,
        filterBoxes,
        filterCowBirthCount,
        filterGeneral,
        filterColor,
        filterSex,
        filterBreed, 
        filterAClass, 
        filterByAgeInMonths, 
        filterByWeight, 
        filterByBirthDate
      );
    
    const [items, __] = res([this.props.animals.items, filter]);
    
    console.log(items);
    console.log(__);

    this.setState(this.createAppState(this.state.dataState, items));
    
  }

  addTranslationsForActiveLanguage = async() => {
    const {activeLanguage} = this.props;

    if (!activeLanguage) {
      return;
    }
    
    const translations = await import(`./translations/${activeLanguage.code}.translation.json`);
    this.props.addTranslationForLanguage(translations, activeLanguage.code);
  }


  render() {
    const options = [1, 2, 3, 4, 5];
    
    const defaultItem = {
        isDefault: true
    };

    const filter = this.state.dataState.globalFilter || { };
    
    return (
      <div className="container-fluid">
        <div className="tab-head d-flex justify-content-between mb-4">
          <div>
            <div className="d-flex align-items-baseline">
              <h3 className="mr-3">Списък на животните във фермата</h3>
              <button className="button-icon icon-color-success">
                <FontAwesomeIcon icon={'question-circle'} />
              </button>
            </div>
          </div>
        </div>
        <form className="table-filter mb-4">
          <Row>
            <Col lg='5'>
              <div className="form-group d-flex">
                <Input placeholder="Търси по име/ушна марка/порода" className="mr-3" value={filter.generalSelection} onChange = { e => this.changeFilterValue(e, "generalSelection")} />
                <button type="button" onClick = { this.applyGlobalFilter.bind(this)} className="btn btn-danger">Търси</button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" >
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <Enum 
                        query ={ANIMAL_SEX} 
                        enumValue ="sex"
                        label='Пол'
                        value={ filter.sexSelection }
                        onChange={e => this.changeFilterValue(e, "sexSelection")}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                  <DropDownList data={options} defaultValue="1" />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                      <Enum 
                          query ={COUNTRY_FROM} 
                          enumValue ="countryFrom"
                          label='Произход'
                          value={filter.countryFromSelection}
                          onChange={e => this.changeFilterValue(e, "countryFromSelection")}
                      />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                  <Query {...this.props} query={ ANIMAL_OTS }>
                      {({ enums }) => {
                          return <DropDownList 
                          data={enums}
                          defaultItem={{ value: 'ОТС', ...defaultItem }}
                          textField="value"
                          dataItemKey="name"
                          value={filter.otsSelection}
                          onChange={e => this.changeFilterValue(e, "otsSelection")}
                          />
                          
                      }}
                      </Query>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <Query {...this.props} query={ACLASS_QUERY_FOR_DDL}>
                      {({ aClasses }) => {
                          return <DropDownList 
                          data={aClasses.items}
                          defaultItem={{ className: 'Категория', ...defaultItem }}
                          textField="className"
                          dataItemKey="classID"
                          value={filter.aClassSelection}
                          onChange={e => this.changeFilterValue(e, "aClassSelection")}
                          />
                          
                      }}
                      </Query>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                      
                  <Query {...this.props} query={ BOX_QUERY }>
                      {({ locations }) => {
                          return <DropDownList 
                            data={ locations.items }
                            defaultItem={{ name: 'Бокс', ...defaultItem }}
                            textField="name"
                            dataItemKey="value"
                            value={filter.filterBox}
                            onChange={e => this.changeFilterValue(e, "filterBoxSelection")}
                            />
                          
                      }}
                      </Query>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                      <Enum 
                          query ={ANIMAL_BREEDING_STATUS} 
                          enumValue ="breadingStatus"
                          label='Реп.Статус'
                          value={filter.animalRepStatusSelection}
                          onChange={e => this.changeFilterValue(e, "animalRepStatusSelection")}
                      />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <DropDownList data={options} defaultValue="1" />
                  </div>
                </Col>           
                <Col lg="3">
                  <div className="form-group">
                  <Query {...this.props} query={BREED_QUERY_FOR_DDL}>
                      {({ breeds }) => {
                          return <DropDownList 
                          data={breeds.items}
                          defaultItem={{ breedName: 'Порода', ...defaultItem }}
                          textField="breedName"
                          dataItemKey="breedsId"
                          value={filter.breedSelection}
                          onChange={e => this.changeFilterValue(e, "breedSelection")}
                          />
                          
                      }}
                      </Query>  
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                      
                  <Query {...this.props} query={ BARN_QUERY }>
                      {({ locations }) => {
                          return <DropDownList 
                            data={ locations.items }
                            defaultItem={{ name: 'Обор', ...defaultItem }}
                            textField="name"
                            dataItemKey="value"
                            value={filter.barn}
                            onChange={e => this.changeFilterValue(e, "barnSelection")}
                            />
                          
                      }}
                      </Query>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                      <Enum 
                          query ={ANIMAL_REGISTER} 
                          enumValue ="register"
                          label='Регистър'
                          value={filter.animalRegister}
                          onChange={e => this.changeFilterValue(e, "animalRegisterSelection")} 
                      />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                      <Enum 
                          query={ANIMAL_INPUT} 
                          enumValue="typeInput"
                          label="Придобиване"
                          value={filter.animalInputTypeSelection}
                          onChange={e => this.changeFilterValue(e, "animalInputTypeSelection")} 
                      />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    
                    <Query {...this.props} query={COLORS_QUERY_FOR_DDL}>
                      {({ color }) => {
                          return <DropDownList 
                            data={color.items}
                            defaultItem={{ colorName: 'Цвят', ...defaultItem }}
                            textField="colorName"
                            dataItemKey="colorID"
                            value={filter.colorSelection}
                            onChange={e => this.changeFilterValue(e, "colorSelection")}
                            />
                          
                      }}
                      </Query>  
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <DropDownList data={options} defaultValue="1" />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <DropDownList 
                          data={[
                            {text: "1", value: 1}, 
                            {text: "2", value: 2}, 
                            {text: "3", value: 3}, 
                            {text: "4", value: 4}, 
                            {text: "5", value: 5}, 
                            {text: "6", value: 6},
                            {text: "7", value: 7},
                            {text: "8", value: 8},
                            {text: "9", value: 9},
                            {text: "10", value: 10}
                          ]
                          }
                          defaultItem={{ text: 'Брой отлв.', ...defaultItem }}
                          textField="text"
                          dataItemKey="value"
                          value={ filter.cowBirthCountSelection }
                          onChange={e => this.changeFilterValue(e, "cowBirthCountSelection")}
                          />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <DropDownList data={options} defaultValue="1" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row>
                <Col lg="3">
                  <div className="form-group mb-0">
                    <label htmlFor="exampleInputEmail1" className="small">Родени от</label>
                    <DatePicker format={"dd/MM/yyyy"} formatPlaceholder={{ year: 'год', month: 'мес', day: 'ден' }} onChange = { e => this.changeFilterValue(e, "BornBefore")} value={filter.BornBefore}  />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group mb-0 ">
                    <label htmlFor="exampleInputEmail1" className="small">Родени до</label>
                    <DatePicker format={"dd/MM/yyyy"} formatPlaceholder={{ year: 'год', month: 'мес', day: 'ден' }} onChange = { e => this.changeFilterValue(e, "BornUntil")} value={filter.BornUntil} />
                  </div>
                </Col>
                <div className='w-100'></div>
                <Col lg="3">
                  <div className="form-group mb-0">
                    <label htmlFor="exampleInputEmail1" className="small">Възраст(м) от</label>
                    <Input onChange = { e => this.changeFilterValue(e, "AgeInMonthsFrom")} value ={filter.AgeInMonthsFrom} />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group mb-0">
                    <label htmlFor="exampleInputEmail1" className="small">Възраст(м) до</label>
                    <Input onChange = { e => this.changeFilterValue(e, "AgeInMonthsTo")} value ={filter.AgeInMonthsTo} />
                  </div>
                </Col>
                <div className='w-100'></div>
                <Col lg="3">
                  <div className="form-group mb-0">
                    <label htmlFor="exampleInputEmail1" className="small">Тегло от</label>
                    <Input onChange = { e => this.changeFilterValue(e, "WeigthFrom")} value ={filter.WeigthFrom}/>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group mb-1">
                    <label htmlFor="exampleInputEmail1" className="small">Тегло до</label>
                    <Input onChange = { e => this.changeFilterValue(e, "WeigthTo")} value ={filter.WeigthTo} />
                  </div>
                </Col>
                <Col lg="6" className="d-flex">
                  <button type="button" className="btn btn-primary align-self-end mb-1">Колони</button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>

        <ExcelExport
          data={this.state.excelItems.data}
          group={this.state.dataState.group && this.state.dataState.group.length ? this.state.dataState.group : false}
          ref={ exporter => { this._export = exporter; }}
        >
          <Grid
            total={this.state.excelItems.length}
            resizable={true}
            reorderable={true}
            pageable={false}
            filterable={false}
            sortable={{ mode: 'multiple' }}
            groupable={true}
            data={this.state.result}
            onDataStateChange={this.dataStateChange.bind(this)}
            {...this.state.dataState}
            onExpandChange={this.expandChange}
            expandField="expanded"
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="k-button k-primary"
                onClick={this.export}
              >Експорт в Excel</button>
            </GridToolbar>
            <Column title="Ветис №" width="120px" cell={cellProps => <DetailsCell {...cellProps} />}/>
            <Column field="vID" title="ВИН" width="120px" />
            <Column field="dateOfBirth" title="Дата на раждане" width="160px" />
            <Column field="ageInMonths" title="Възр (м.)" width="100px" />
            <Column title="Пол" width="80px" cell={_ => {
              const sex = SEXES.find(s => s.id == _.dataItem.sex) || { };
              return <td>{sex.text}</td>;
            } }/>
            <Column field="aClass.className" title="Категория" width="120px" />
            <Column field="color.colorName" title="Цвят" width="100px" />
            <Column field="breed.breedShort" title="Порода" width="100px" />
            <Column field="formattedBreedComposition" title="Породен композит" width="170px" />
            <Column field="name" title="Име" width="120px" />
            <Column field="dam.lID" title="Майка" width="120px" />
            <Column field="sire.lID" title="Баща" width="120px" />
            <Column field="location.location.locationName" title="Локация" width="100px" />
            <Column field="" title="Стадо" width="80px" />
            <Column field="weight"  title="Посл. тегло (кг.)" width="150px" />
            <Column field="dateWeight" title="Дата Посл. тегло" width="160px" />
            <Column field="currentAGD.animalANG" title="Текущ СДП (кг/д)" width="160px" />
            <Column field="adgOverall" title="Общо СДП (кг/д)" width="160px" />
            <Column field="event" title="Последна бележка Последно събитие" width="320px" />
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}

export default withLocalize(AnimalsList);