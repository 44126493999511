import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Mutation from '../../../../../../data/mutation';
import { ADD_ANIMAL_VACCINE, GET_ANIMAL_VACCINE} from '../../../../../../data/animal/animal-query';
import FarmClientDropdown from '../farm-client-dropdown';

class AddVaccineModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            vaccineObj: props,
            submitDisabled:true,
            tableType:'vaccine'
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });

        if (!this.state.visible) {
            this.resetState();
        }
    }


    handleFormChange(e) {
        let objCopy = { ...this.state.vaccineObj };
        
        if ('farmClientID' === e.target.name) {
            objCopy[e.target.name] = e.target.value.farmClientID;
        } else {
            objCopy[e.target.name] = e.target.value;
        }
        const isFormSubmitable = Object.keys(objCopy).length > 1 ? false:true;
        this.setState({
            vaccineObj: objCopy,
            submitDisabled:isFormSubmitable
        });
    }

    handleSubmit(e, addAnimalVaccineTrigger) {
        e.preventDefault();
        let stateCopy = {...this.state.vaccineObj};
        let dataToSave;

        if (!stateCopy.hasOwnProperty('animalVaccineDate')) {
            stateCopy['animalVaccineDate'] = new Date();
            dataToSave ={...stateCopy};
        }else{
            dataToSave ={...this.state.vaccineObj};
        }
 
        if (Object.keys(dataToSave).length > 1) {
            addAnimalVaccineTrigger({
                variables:
                {
                    animalVaccineAdd: dataToSave
                }
            });
        }
    }

    resetState(){
        const animalID = this.state.vaccineObj.animalID;
        this.setState({
            vaccineObj: {
                animalID: animalID
            },
            submitDisabled: true
        })
    }

    render() {
        return (
            <Mutation mutation={ADD_ANIMAL_VACCINE} awaitRefetchQueries={true}
                refetchQueries={[{ query: GET_ANIMAL_VACCINE, variables: { id: this.state.vaccineObj.animalID } }]}
                onCompleted={() => this.toggleDialog()}
            >
                {(addAnimalVaccineTrigger) => (
                    <div>
                        <button className="btn btn-danger" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
                        {this.state.visible && <Dialog className="health-modal" title={"Добави събитие"} onClose={this.toggleDialog} minWidth='500px' height="80%">
                            <form className="health-form" onSubmit={e => this.handleSubmit(e, addAnimalVaccineTrigger)}>
                                <Row>
                                    <Col lg={{ size: 8, offset: 2 }}>

                                        <div className="form-group">
                                            <label>Тип</label>
                                            <Input type="text" name="animalVaccineDrug" onChange={this.handleFormChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Ветеринарен лекар</label>
                                            <FarmClientDropdown tableType={this.state.tableType} handleFormChange={this.handleFormChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Дата</label>
                                            <DatePicker format="dd.MM.yyyy" value={this.state.vaccineObj.animalVaccineDate ? this.state.vaccineObj.animalVaccineDate : new Date()} name="animalVaccineDate" onChange={this.handleFormChange} />
                                        </div>

                                        <div>
                                            Информация
                                             <hr />
                                            <div className="form-group">
                                                <label>Доза</label>
                                                <Input type="text" name="animalVaccineDose" onChange={this.handleFormChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Месеци до следваща ваксина</label>
                                                <Input type="number" name="animalVaccineEndMonths" onChange={this.handleFormChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Цена</label>
                                                <Input type="number" name="animalVaccinePrice" onChange={this.handleFormChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Карентен срок</label>
                                                <Input type="number" name="animalVaccineKarentDays" onChange={this.handleFormChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Бележка</label>
                                                <textarea className="k-textarea" name="animalVaccineComment" onChange={this.handleFormChange} ></textarea>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button type="submit" disabled={this.state.submitDisabled} className="btn btn-success mr-3">Добави</button>
                                            <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Dialog>}
                    </div>
                )}
            </Mutation>
        );
    }
}

export default AddVaccineModal;