import React from 'react';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../animal-card.css';
import '../../../../tabs/animal-card-tab.css';
import '../../entries-tabs.css';
import TabNavButtons from '../../tab-nav-buttons';



const GroupAndLocationTab = props => {
    const options = [1, 2, 3, 4, 5];

    return (
        <div className="tab">
            <form>
                <div className="tab-head d-flex justify-content-between">
                    <div>
                        <div className="d-flex align-items-baseline">
                            <h3 className="mr-3">| ДАННИ ЗА ЛОКАЦИЯТА | НАПРАВЛЕНИЕ |</h3>
                            <button className="button-icon icon-color-success">
                                <FontAwesomeIcon icon={'question-circle'} />
                            </button>
                        </div>
                        <p className="info-text-red" >Определете локацията, на която ще се отглеждат животните!</p>
                    </div>
                </div>
                <Row>
                    <Col lg="6">
                        <Row>
                            <Col lg="8">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Стадо</label>
                                    <DropDownList data={options} defaultValue="1" />
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Обор / Пасище</label>
                                    <DropDownList data={options} defaultValue="1" />
                                </div>
                            </Col>
                            <Col lg="8">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Направление</label>
                                    <DropDownList data={options} defaultValue="1" />
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Бокс / Падок</label>
                                    <DropDownList data={options} defaultValue="1" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <TabNavButtons parentProps={props} />
            </form>
        </div>
    )
};

export default GroupAndLocationTab;