import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import './animal-card.css';
import Breadcrumbs from '../../layout/breadcrumbs';

import Query from "../../../data/query";
import DatesHelper from '../../../tools/DatesHelper';
import { GET_ANIMAL_DETAILS } from '../../../data/animal/animal-query';
import { getAnimalAgeInMonths, getAnimalLastWeightObj, generateBreedCompositionString, 
  generateLocationString, generateSelectionString, generateDryWetStatusString,generatePEDString } from './animal-utilities';
import { mapTypeInput, mapBreedingStatus, mapHornStatus } from './animal-mapping-functions';
import AnimalCardTabs from './tabs/animal-card-tabs';
import AnimalPicBox from './animal-pic-box';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAnimal } from "../../../data/redux/actions/animal-actions";

const AnimalCard = props => {
  const breadcrumbs = [{ title: 'Животни', href: '/animals' }, { title: 'Избор на животни', href: '/animals/list' }, { title: 'Картон животно / FR4004387287' }];
  const id = props.animalId;
  
  const generateVetisStatus= animal => {
    const vetisArr = animal.vetis;
    const vetisObj = animal.vetis[0];

    if (!vetisArr || vetisArr.length === 0 || !vetisObj || !vetisObj.animalVetisLID
      || !vetisObj.animalVetisEID || !vetisObj.animalVetisDOB || !vetisObj.animalVetisSex
      || !vetisObj.color || !vetisObj.color.colorID || !vetisObj.animalVetisDamLID) {
      return <span className="text-inf">Липсват данни</span>;
    }

    if (vetisObj.animalVetisLID !== animal.lID || vetisObj.animalVetisEID !== animal.electronicId
      || vetisObj.animalVetisDOB + '' !== animal.dateOfBirth + ''
      || vetisObj.animalVetisSex !== animal.sex
      || !animal.color || vetisObj.color.colorID !== animal.color.colorID
      || !animal.dam || vetisObj.animalVetisDamLID !== animal.dam.lID) {
        return <span className="text-accent">Грешки</span>;
    }
    return <span className="text-success">ОК</span>;
  };

  return (
    <Query {...props} query={GET_ANIMAL_DETAILS} variables={{ id }}>
      {({ animal }) => {
        props.setCurrentAnimal(animal);
        animal.ageInMonths = getAnimalAgeInMonths(animal.dateOfBirth);
        animal.lastWeight = getAnimalLastWeightObj(animal);
        animal.formattedBreedComposition = generateBreedCompositionString(animal);
        animal.animalADG = animal.animalADG || [];
        return (
          <div>
            <div className="container-fluid">
              <Breadcrumbs pathLinks={breadcrumbs} />
            </div>
            <div className="container-fluid container-s">
              <Row>
                <Col md="6">
                  <AnimalPicBox animal={animal} />
                </Col>
                <Col md="3">
                  <table className="table table-custom">
                    <thead>
                      <tr>
                        <th colSpan="2">Общи данни</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="col-head">ВЕТИС №</td>
                        <td>{animal.lID}</td>
                      </tr>
                      <tr>
                        <td className="col-head">ВИН №</td>
                        <td>{animal.vID}</td>
                      </tr>
                      <tr>
                        <td className="col-head">EID №</td>
                        <td>{animal.electronicId || '-'}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Име</td>
                        <td>{animal.name}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Паспорт №</td>
                        <td>{animal.passportNumber || '-'}</td>
                      </tr>
                      <tr>
                        <td className="col-head">ДР</td>
                        <td>{DatesHelper.parseAndFormatDate(animal.dateOfBirth)} ({animal.ageInMonths} м)</td>
                      </tr>
                      <tr>
                        <td className="col-head">Пол / Кат.</td>
                        <td>{animal.sex === 'MALE' ? 'М' : 'Ж'} ({animal.aClass.className})</td>
                      </tr>
                      <tr>
                        <td className="col-head">Порода</td>
                        <td>{animal.formattedBreedComposition}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Рога</td>
                        <td>{mapHornStatus(animal.hornStatusID)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Цвят</td>
                        <td>{animal.color.colorName}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Локация</td>
                        <td>{generateLocationString(animal)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md="3">
                  <table className="table table-custom">
                    <thead>
                      <tr>
                        <th colSpan="2">Други данни</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="col-head">Стадо</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td className="col-head">Придобиване</td>
                        <td>{mapTypeInput(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Собственост</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td className="col-head">Субсидии / Задържане</td>
                        <td>{generateSelectionString(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Статус крава</td>
                        <td>{mapBreedingStatus(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Лактираща</td>
                        <td>{generateDryWetStatusString(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Инвентарен статус</td>
                        <td>{animal.active ? 'Активна' : 'Неактивна'}</td>
                      </tr>
                      <tr>
                        <td className="col-head">ВЕТИС статус</td>
                        <td>{generateVetisStatus(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">PED регистър</td>
                        <td>{generatePEDString(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Баща</td>
                        <td>
                          {animal.sire && animal.sire.animalId ?
                            <Link className="text-inf" to={'/animals/' + (animal.sire.animalId)}>{`${animal.sire.lID} ${generateBreedCompositionString(animal.sire)}`}</Link>
                            :
                            <span className="text-inf">-</span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="col-head">Майка</td>
                        <td>
                          {animal.dam && animal.dam.animalId ?
                            <Link className="text-inf" to={'/animals/' + (animal.dam.animalId)}>{`${animal.dam.lID} ${generateBreedCompositionString(animal.dam)}`}</Link>
                            :
                            <span className="text-inf">-</span>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
            <AnimalCardTabs animal={animal} />
          </div>
        );
      }}
    </Query>
  )
};


export default connect(({ auth }) => ({ auth }), dispatch => bindActionCreators({ setCurrentAnimal: setAnimal }, dispatch))(AnimalCard);